import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { map } from 'rxjs/operators';

import { FormService } from 'app/services/form.service';
import { UploadFilesService } from 'app/services/upload-files.service';
import { VideoMeta } from 'app/interfaces/server.interface';
import { DataService } from 'app/services/data.service';

@Component({
    selector: 'upload-video',
    templateUrl: './upload-video.modal.html',
    styleUrls: ['./upload-video.modal.scss']
})

export class UploadVideoDialog {

    filePath: string;
    fileToUpload: File;
    showProgress: boolean;
    uploadVideoForm: FormGroup;
    progress$ = this.uploadFilesService.progress$;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<UploadVideoDialog>,
        private uploadFilesService: UploadFilesService,
        private dataService: DataService,
        private formBuilder: FormBuilder,
        private formService: FormService) { }

    ngOnInit() {
        this.getFilePath();
        this.setForm();
    }

    chooseFile(file: any) {
        this.fileToUpload = file.target.files[0];
    }

    upload() {
        this.showProgress = true;
        this.uploadFilesService.upload([this.fileToUpload], this.filePath)
            .subscribe(
                () => this.setVideoMetadata(),
                err => {
                    if (err.code === 'RequestAbortedError')
                        console.warn(err.message);
                    else
                        console.error('upload file failed', err);

                    this.showProgress = false;
                }
            );
    }

    cancel() {
        this.uploadFilesService.abortUpload();
        this.closeDialog('cancel');
    }

    private getFilePath() {
        this.dataService.api({
            type: 'uploadVideo',
            data: {
                collectionKey: this.data.collection.collectionKey
            }
        }).subscribe(res => {
            this.filePath = res.uploadPath;
        }, () => this.closeDialog('cancel'));
    }

    private setForm() {
        this.uploadVideoForm = this.formBuilder.group({
            recordStartTime: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            recordStopTime: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            jobName: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }]
        });
    }

    private setVideoMetadata() {
        this.showProgress = false;
        let data: VideoMeta = Object.assign({},
            this.uploadVideoForm.value,
            { filePath: `${this.filePath}${this.fileToUpload.name}` },
            { collectionKey: this.data.collection.collectionKey }
        );
        this.dataService.api({
            type: 'setVideoMetadata',
            data
        }).subscribe(() => this.closeDialog('success'));
    }

    private closeDialog(val?: string) {
        this.showProgress = false;
        this.dialogRef.close(val);
    }
}
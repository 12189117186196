import { Pipe, PipeTransform } from '@angular/core';
import { i18nMap } from 'app/maps/i18n.map';
import { template, at } from 'lodash';

@Pipe({
    name: 'i18n'
})
export class I18nPipe implements PipeTransform {
    transform(value: string, args?: any): any {
        let res = at(i18nMap, [value])[0];
        if (res === undefined)
            return value;
        if (args) 
            res = template(res)(args);
        return res || value;
    }
}

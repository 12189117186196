let awsConfigMapTemplate = {
    region: 'eu-west-1',
    bucketName: 'bucket-knesset-rk-dev-col',
    identityPoolId: 'eu-west-1:5bb2e9b8-9b53-4649-9afc-49827c23b6d5',
    userPoolId: 'eu-west-1_GFMHfLAkE',
    clientId: '2frr9lk76k9se5al45dc53mv7n',
    loginsKey: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_GFMHfLAkE',
    apiVersion: '2006-03-01',
    ACL: 'public-read',
    signatureVersion: 'v4',
    encryption: 'aws:kms',
    KMSKeyId: '70dca56e-65db-4917-9e32-19886ef6007b',
};

export let awsConfigMap = awsConfigMapTemplate;

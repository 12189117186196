export interface ApiCfg {
    type: string;
    data?: any;
    urlParams?: any;
    disableBI?: boolean;
    disableErrorHandler?: boolean;
    queryParams?: {
        [key: string]: any;
    };
    headers?: {
        [name: string]: string;
    };
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

export const API_CFG_DEFAULTS = {
    disableBI: false,
    data: {}, 
    disableErrorHandler: false
};

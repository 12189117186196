/**
 * This map defines the set-up of all the API calls the app can make. It is primarily
 * used by the DataService.
 */
export interface ApiMap {
    [name: string]: {
        url: string;
        method: 'get' | 'post' | 'put' | 'delete';
        mockup?: any;
        errors?: {
            [name: number]: string;
        };
        headers?: {
            [name: string]: string;
        };
        responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    };
}

export const apiMap: ApiMap = {
    getToken: { // TODO
        url: '/api/token/',
        method: 'get',
        mockup: {
            token_type: 'Bearer',
            access_token: '',
            refresh_token: '',
            expires_in: 60000
        }
    },
    getAnonymousToken: { // TODO
        url: '/api/token/',
        method: 'get',
        mockup: {
            token_type: 'Bearer',
            access_token: '',
            refresh_token: '',
            expires_in: 60000
        }
    },
    getAllCollections: {
        url: '/api/collection/getCollectionsAndGroups/',
        method: 'get'
    },
    getColectionPeopleCount: {
        url: '/api/collection/peoplecount/',
        method: 'post'
    },
    getCollectionIds: {
        url: '/api/collection/getIds/',
        method: 'post'
    },
    getAllCollectionsExt: {
        url: '/api/collection/getAllExt/',
        method: 'post',
    },
    getVideoJobs: {
        url: '/api/collection/getVideoJobs/',
        method: 'post'
    },
    getVideoBatches: {
        url: '/api/collection/getVideoBatches/',
        method: 'post',
    },
    deleteCollection: {
        url: '/api/collection/delete/',
        method: 'delete'
    },
    createCollection: {
        url: '/api/collection/create/',
        method: 'post'
    },
    deleteId: {
        url: '/api/collection/deleteIds/',
        method: 'post'
    },
    findId: {
        url: '/api/collection/findId/',
        method: 'post'
    },
    getCollection: {
        url: ' /api/collection/getIds',
        method: 'post',
    },
    indexingError: {
        url: '/api/collection/indexingError/',
        method: 'post'
    },
    uploadVideo: {
        url: '/api/collection/uploadVideoPath/',
        method: 'post'
    },
    setVideoMetadata: {
        url: '/api/collection/setVideoMetadata/',
        method: 'post'
    },
    getJobsList: {
        url: '/api/collection/jobsList/',
        method: 'post'
    },
    getJobsResult: {
        url: '/api/jobs/result/',
        method: 'post'
    },
    downloadResults: {
        url: '/api/jobs/downloadResults/',
        method: 'post',
        responseType: 'blob'
    },
    uploadYoutubeVideo: {
        url: '/api/collection/uploadYoutubeVideo/',
        method: 'post',
    },
    sendVideoBatch: {
        url: '/api/collection/sendVideoBatchJob/',
        method: 'post',
    },
    getFileBayBucket: {
        url: '/api/collection/getBayBucket/',
        method: 'post',
    },
    imageBatchJob: {
        url: '/api/collection/sendImageBatchJob/',
        method: 'post',
    },
    listImageBatchJobs: {
        url: ' /api/batches/images/list/',
        method: 'post',
    },
    listLabelJobs: {
        url: '/api/jobs/listLabelJobs/',
        method: 'post',
    },
    getImageJobsResult: {
        url: '/api/batches/images/result/',
        method: 'post',
    },
    getImageJobsResultCsv: {
        url: '/api/batches/images/result/',
        method: 'post',
        responseType: 'blob'
    },
    getImageBatches: {
        url: '/api/batches/images/getAll/',
        method: 'post',
    },
    getImageBatchesSingleResult: {
        url: '/api/batches/images/singleResult/',
        method: 'post'
    },
    listGoogleFiles: {
        url: '/api/drive/list/',
        method: 'post'
    },
    sendBatchToGoogle: {
        url: '/api/drive/sendBatch/',
        method: 'post'
    },
    setTags: {
        url: '/api/tags/set/',
        method: 'post'
    },
    getTagKeys: {
        url: '/api/tags/get/keys/',
        method: 'post'
    },
    getTagValues: {
        url: '/api/tags/get/values/',
        method: 'post'
    },
    getSeenNear: {
        url: '/api/person/listSeenNear/',
        method: 'post',
        // mockup: [{ civilId: "204006115", count: 244},{ civilId: "203114485", count: 4},{ civilId: "22222", count: 2}]
    },
    getSeenNearOccurences: {
        url: ' /api/person/seenNearOccurences/',
        method: 'post'
    },
    getCollectionMetadata: {
        url: '/api/collection/metadata/',
        method: 'post'
    },
    getPersonMetadata: {
        url: '/api/person/metadata/',
        method: 'post'
    },
    getPersonOccurences: {
        url: '/api/person/occurences/',
        method: 'post'
    },
    getJobLabels: {
        url: '/api/jobs/getJobLabels/',
        method: 'post'
    },
    getLabelInstances: {
        url: '/api/jobs/getLabelInstances/',
        method: 'post'
    },
    changeId: {
        url: '/api/collection/changeId/',
        method: 'post'
    },
};

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'alert-dialog',
    template: `
        <h2 mat-dialog-title class="font-5">{{ data.title | i18n }}</h2>
        <mat-dialog-content>
            <div class="font-3" align="center">{{ data.body | i18n }}</div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" [mat-dialog-close]="'ok'">{{ 'G.ok' | i18n }}</button>
        </mat-dialog-actions>
    `
})

export class AlertDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FormService } from 'app/services/form.service';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { awsConfigMap } from 'app/maps/aws-config.map';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { ModalService } from 'app/services/modal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'set-password',
    templateUrl: './set-password.modal.html',
    styles: [`
        mat-dialog-actions {
            justify-content: space-around;
        }
        .passwords-mismatch {
            position: relative;
            bottom: 10px;
            right: 3px;
        }
    `]
})

export class SetPasswordDialog {
    form: FormGroup;
    passwordsMismatch: boolean;
    ngDestroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private dialogRef: MatDialogRef<SetPasswordDialog>) { }

    ngOnInit() {
        const { passwordPattern, requiredPassword } = this.formService.validators;
        this.form = this.formBuilder.group({
            password: [null, {
                validators: [requiredPassword, passwordPattern]
            }],
            confirmPassword: [null, {
                validators: [requiredPassword]
            }],
        });

        this.form.valueChanges.pipe(takeUntil(this.ngDestroy$)).subscribe(val => {
            this.passwordsMismatch = false;
        });
    }

    ngOnDestroy() {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
    }

    submitPassword() {
        if (this.form.valid) {
            if (this.passwordsMismatchOnForm()) {
                this.passwordsMismatch = true;
            } else {
                this.dialogRef.close({ password: this.form.value.password });
            }
        }
    }

    passwordsMismatchOnForm() {
        let pass = this.form.controls.password.value;
        let confirmPass = this.form.controls.confirmPassword.value;
        return pass !== confirmPass;
    }
}
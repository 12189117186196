import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

import { DataService } from 'app/services/data.service';
import { FailedsIndexes, FailedsIndexItem } from 'app/interfaces/server.interface';

@Component({
    selector: 'display-faileds',
    templateUrl: './display-faileds.modal.html',
    styleUrls: ['./display-faileds.modal.scss']
})

export class DisplayFailedsDialog {

    errors: FailedsIndexItem[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dataService: DataService) { }

    ngOnInit() {
        this.getData();
    }

    private getData() {
        this.dataService.api({
            type: 'indexingError',
            data: {
                collectionKey: this.data.collection.collectionKey
            }
        }).subscribe((errors: FailedsIndexes) => {
            this.errors = errors.items;
            console.log(this.errors);
        });
    }
}
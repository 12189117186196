import { NgModule } from '@angular/core';
import { SharedModule } from 'app/modules/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

// modals
import { BoolDialog } from 'app/modals/bool/bool.modal';
import { AlertDialog } from 'app/modals/alert/alert.modal';
import { SearchIdDialog } from 'app/modals/search-id/search-id.modal';
import { DeleteIdsDialog } from 'app/modals/delete-ids/delete-ids.modal';
import { UploadVideoDialog } from 'app/modals/upload-video/upload-video.modal';
import { DisplayFailedsDialog } from 'app/modals/display-faileds/display-faileds.modal';
import { CreateCollectionDialog } from 'app/modals/create-collection/create-collection.modal';
import { ItemProcessResultsDialog } from 'app/modals/item-process-result/item-process-result.modal';
import { CreateUserDialog } from 'app/modals/create-user/create-user.modal';
import { UploadYoutubeVideoDialog } from 'app/modals/upload-youtube-video/upload-youtube-video.modal';
import { SetPasswordDialog } from 'app/modals/set-password/set-password-modal';
import { MFADialog } from 'app/modals/mfa/mfa.modal';
import { VideoBatchDialog } from 'app/modals/video-batch/video-batch.modal';
import { ImageBatchDialog } from 'app/modals/image-batch/image-batch.modal';
import { ImagesItemProcessResultsDialog } from 'app/modals/images-item-process-result/images-item-process-result.modal';
import { EditUserDialog } from 'app/modals/edit-user/edit-user.modal';
import { EditTagsDialog } from 'app/modals/edit-tags/edit-tags.modal';
import { SeenNearDialog } from 'app/modals/seen-near/seen-near';
import { SingleObjectDialog } from 'app/modals/single-object/single-object';
import { EditCivilIdDialog } from 'app/modals/edit-civil-id/edit-civil-id-modal';
import { DeleteCivilIdsDialog } from 'app/modals/delete-civil-ids/delete-civil-ids.modal';

@NgModule({
    imports: [
        SharedModule,
        ReactiveFormsModule
    ],
    declarations: [
        BoolDialog,
        AlertDialog,
        SearchIdDialog,
        DeleteIdsDialog,
        UploadVideoDialog,
        DisplayFailedsDialog,
        CreateCollectionDialog,
        ItemProcessResultsDialog,
        ImagesItemProcessResultsDialog,
        CreateUserDialog,
        EditUserDialog,
        UploadYoutubeVideoDialog,
        SetPasswordDialog,
        MFADialog,
        VideoBatchDialog,
        ImageBatchDialog,
        EditTagsDialog,
        SeenNearDialog,
        SingleObjectDialog,
        EditCivilIdDialog,
        DeleteCivilIdsDialog
    ],
    entryComponents: [
        BoolDialog,
        AlertDialog,
        SearchIdDialog,
        DeleteIdsDialog,
        UploadVideoDialog,
        DisplayFailedsDialog,
        CreateCollectionDialog,
        ItemProcessResultsDialog,
        ImagesItemProcessResultsDialog,
        CreateUserDialog,
        EditUserDialog,
        UploadYoutubeVideoDialog,
        SetPasswordDialog,
        MFADialog,
        VideoBatchDialog,
        ImageBatchDialog,
        EditTagsDialog,
        SeenNearDialog,
        SingleObjectDialog,
        EditCivilIdDialog,
        DeleteCivilIdsDialog

    ],
    exports: [
        BoolDialog,
        AlertDialog,
        SearchIdDialog,
        DeleteIdsDialog,
        UploadVideoDialog,
        DisplayFailedsDialog,
        CreateCollectionDialog,
        ItemProcessResultsDialog,
        ImagesItemProcessResultsDialog,
        CreateUserDialog,
        EditUserDialog,
        UploadYoutubeVideoDialog,
        SetPasswordDialog,
        MFADialog,
        VideoBatchDialog,
        ImageBatchDialog,
        EditCivilIdDialog,
        DeleteCivilIdsDialog

    ]
})
export class ModalsModule { }

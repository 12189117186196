import { Observable } from 'rxjs/internal/Observable';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from 'app/services/auth.service';

@Injectable()
export class LoginResolver implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router) { }

    canActivate(): Observable<boolean> {
        return new Observable(observer => {
            this.authService.ready$.subscribe(isReady => {
                if (!isReady) return;

                if (this.authService.isLoggedIn) {
                    console.info(`login.resolver -> ${this.authService.user}-loggedin -> redirect to sys`);
                    this.router.navigate(['/sys']);
                    observer.next(false);
                } else {
                    observer.next(true);
                }

            });
        });
    }
}

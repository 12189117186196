/**
 * This service handles the app's global busy-indicator (BI). It works via a counter.
 * When the counter is 0, the BI is off. When it is greater than 0, the BI is on.
 * You can increase or decrease the counter and thus control the BI visibility.
 * The visibility is controlled via busy-indicator.component, which observes the counter.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class BusyIndicatorService {
    private counter = 0;
    private processes$: BehaviorSubject<number> = new BehaviorSubject(0);

    snapshot() {
        return this.processes$.getValue();
    }

    observe() {
        return this.processes$;
    }

    increase() {
        this.counter++;
        this.processes$.next(this.counter);
    }

    decrease() {
        this.counter--;
        if (this.counter < 0) {
            // console.error('minus value of counter [busy indicator service]');
            this.counter = 0;
        }
        this.processes$.next(this.counter);
    }
}

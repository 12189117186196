import { Component } from '@angular/core';
import { Directionality } from '@angular/cdk/bidi';
import { Subscription } from 'rxjs/internal/Subscription';

import { AuthService } from 'app/services/auth.service';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet *ngIf="isAuthServiceReady"></router-outlet>
        <busy-indicator></busy-indicator>
    `
})
export class AppComponent {

    readySub: Subscription;
    isAuthServiceReady = false;

    /** Whether the widget is in RTL mode or not. */
    private isRtl: boolean;

    /** Subscription to the Directionality change EventEmitter. */
    private _dirChangeSubscription = Subscription.EMPTY;

    constructor(dir: Directionality,
        private authService: AuthService) {
            
        this.isRtl = dir.value === 'rtl';
        this._dirChangeSubscription = dir.change.subscribe(() => {});
    }

    ngOnInit() {
        // We need to retrieve the user logged-in state before initializing the router
        this.readySub = this.authService.ready$.subscribe(isReady => {
            if (isReady) {
                this.isAuthServiceReady = true;
            }
        });
    }

    ngOnDestroy() {
        this._dirChangeSubscription.unsubscribe();
        this.readySub.unsubscribe();
    }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FormService } from 'app/services/form.service';

@Component({
    selector: 'create-collection',
    templateUrl: './create-collection.modal.html',
    styles: [`
        mat-dialog-actions {
            justify-content: space-around;
        }
    `]
})

export class CreateCollectionDialog {

    form: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private formService: FormService) { }

    ngOnInit() {
        this.setForm();
    }

    private setForm() {
        this.form = this.formBuilder.group({
            name: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            description: null
        });
    }
}
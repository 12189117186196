import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FormService } from 'app/services/form.service';
import { DataService } from 'app/services/data.service';
import { SearchId } from 'app/interfaces/server.interface';

@Component({
    selector: 'search-id',
    templateUrl: './search-id.modal.html',
    styleUrls: ['./search-id.modal.scss']
})

export class SearchIdDialog {

    searchRes: SearchId;
    searchForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dataService: DataService,
        private formBuilder: FormBuilder,
        private formService: FormService) { }

    ngOnInit() {
        this.setForm();
        this.setFormEvents();
    }

    search() {
        this.dataService.api({
            type: 'findId',
            data: {
                civilId: this.searchForm.get('civilId').value,
                collectionKey: this.data.collection.collectionKey
            }
        }).subscribe((res: SearchId) => {
            this.searchRes = res;
        });
    }

    private setForm() {
        this.searchForm = this.formBuilder.group({
            civilId: [null, {
                validators: [
                    this.formService.validators.requiredIdNumber,
                ]
            }]
        });
    }

    private setFormEvents() {
        this.searchForm.get('civilId').valueChanges
            .subscribe(() => this.searchRes = null);
    }
}
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { ModalService } from 'app/services/modal.service';
import { FormService } from 'app/services/form.service';
import { AuthService } from 'app/services/auth.service';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { BusyIndicatorService } from 'app/services/busy-indicator.service';

@Component({
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})

export class LoginPage {

    errMsg: string;
    loginForm: FormGroup;

    constructor(
        private router: Router,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private modalService: ModalService,
        private awsAuthService: AwsAuthService,
        private biService: BusyIndicatorService) { }

    ngOnInit() {
        this.setForm();
    }

    login() {
        if (this.loginForm.invalid) return;

        this.biService.increase();
        this.awsAuthService.getAwsToken(
            this.loginForm.get('userName').value,
            this.loginForm.get('password').value)
            .subscribe(token => {
                this.authService.login(token)
                    .pipe(tap(() => this.biService.decrease()))
                    .subscribe(isLoggedIn => {
                        if (isLoggedIn) {
                            this.router.navigate(['/sys']);
                        }
                    }, err => {
                        this.modalService.alert({
                            body: 'serverErrors.NotValidPermission'
                        }).afterClosed().subscribe(() => { });
                    });
            }, awsErr => {
                this.biService.decrease();
                this.errMsg = `serverErrors.${awsErr.code}`;
            });
    }

    private setForm() {
        this.loginForm = this.formBuilder.group({
            userName: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            password: [null, {
                validators: [
                    this.formService.validators.requiredPassword,
                ]
            }]
        });
    }
}

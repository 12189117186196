import { Injectable } from '@angular/core';

@Injectable()
export class FormService {
    // private readonly _passwordPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[a-zA-Z]).*$/;
    private readonly _passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,}$/;
    private readonly _phonePattern = /^\+[0-9]{6,}$/;
    private readonly _emailPattern =  /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    private readonly _idNumberPattern = /^[\d]{9}$/i;

    validators = {
        requiredValue: (ctrl) => !ctrl.value ? { requiredValue: true } : null,
        requiredIdNumber: (ctrl) => !ctrl.value ? { requiredIdNumber: true } : null,
        requiredPassword: (ctrl) => !ctrl.value ? { requiredPassword: true } : null,
        numberPattern: (ctrl) => (ctrl.value && isNaN(ctrl.value)) ? { numberPattern: true } : null,
        idNumberPattern: (ctrl) => (ctrl.value && !ctrl.value.match(this._idNumberPattern)) ? { idNumberPattern: true } : null,
        passwordPattern: (ctrl) => (ctrl.value && !ctrl.value.match(this._passwordPattern)) ? { passwordPattern: true } : null,
        phonePattern: (ctrl) => (ctrl.value && !ctrl.value.match(this._phonePattern)) ? { phonePattern: true } : null,
        emailPattern: (ctrl) => (ctrl.value && !ctrl.value.match(this._emailPattern)) ? { emailPattern: true } : null,
        passwordsMismatch: (otherCtrl: string) => {
            return (ctrl) => {
                if (ctrl.value && ctrl.value !== (ctrl._parent.controls[otherCtrl].value)) {
                    return { passwordsMismatch: true };
                }
                return null;
            }
        },
        range(minValue: number, maxValue: number) {
            return ctrl => {
                if ((+ctrl.value != null) && (+ctrl.value < minValue) || (+ctrl.value > maxValue)) {
                    return {
                        'rangeValidation': {
                            min: minValue,
                            max: maxValue
                        }
                    }
                } else {
                    return null;
                }
            }
        },
        rangeMin(minValue: number) {
            return ctrl => {
                if ((+ctrl.value != null) && +ctrl.value < minValue) {
                    return {
                        'rangeValidationMin': {
                            min: minValue,
                        }
                    }
                } else {
                    return null;
                }
            }
        },
        rangeMax(maxValue: number) {
            return ctrl => {
                if ((+ctrl.value != null) && +ctrl.value > maxValue) {
                    return {
                        'rangeValidationMax': {
                            max: maxValue,
                        }
                    }
                } else {
                    return null;
                }
            }
        },
        idNumberValid: (ctrl) => {
            let id = ctrl.value;
            if (!id) return false;
            id = String(id).trim();
            if (id.length > 9 || id.length < 5 || isNaN(id)) return { invalidId: true };
            // Pad string with zeros up to 9 digits
            id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
            let valid = Array.from(id, Number)
                .reduce((counter, digit, i) => {
                    const step = digit * ((i % 2) + 1);
                    return counter + (step > 9 ? step - 9 : step);
                }) % 10 === 0;
            return valid ? null : { invalidId: true };
        }
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Subscriber } from 'rxjs/internal/Subscriber';
import * as AWS from 'aws-sdk';

import { awsConfigMap } from 'app/maps/aws-config.map';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { AuthService } from './auth.service';
import { TimerService } from './timer.service';

@Injectable()
export class UploadFilesService {

    private totalSize: number;
    private totalLoaded: number;
    private uploadProcesses: any[];
    private progressMap = new Map();

    readonly progress$ = new BehaviorSubject<string>(null);
    get progress() { return this.progress$.getValue(); }

    private abort$ = new BehaviorSubject<boolean>(null);
    get abort() { return this.abort$.getValue(); }

    constructor(private awsAuthService: AwsAuthService,
        private authService: AuthService,
        private timerService: TimerService) { }

    // TODO - Handle access token err 
    upload(files: File[], path: string): Observable<any> {
        return new Observable(observer => {
            const bucketName = awsConfigMap.bucketName;
            this.timerService.resetActivityTimer();
            if (this.abort) {
                observer.complete();
                return;
            }
            this.uploadProcesses = [];
            this.totalLoaded = 0;
            this.totalSize = 0;

            // adds the S3 service, make sure the api version and bucket are correct
            const s3 = new AWS.S3({
                apiVersion: awsConfigMap.apiVersion,
                params: { Bucket: bucketName }
            });

            for (let f of files) {
                this.totalSize += f.size;
            }
            for (let idx in files) {
                if (this.abort) {
                    observer.complete();
                    return;
                }
                const fileName = `${path}${files[idx].name}`;
                this.putObject(s3, fileName, bucketName, files[idx], idx, observer);
            }
        });
    }

    abortUpload() {
        this.abort$.next(true);
        if (!this.uploadProcesses) return;
        for (let process of this.uploadProcesses)
            process.abort();
    }

    private putObject(s3: AWS.S3, fileName: string, Bucket: string, Body: File, idx: string, observer: Subscriber<any>) {
        this.uploadProcesses.push(s3.putObject({
            Key: fileName, Bucket, Body, ACL: awsConfigMap.ACL,
            ServerSideEncryption: awsConfigMap.encryption,
            SSEKMSKeyId: awsConfigMap.KMSKeyId
        }));
        this.uploadProcesses[idx]
            .on('httpUploadProgress', (event: AWS.S3.ManagedUpload.Progress) => this.calcProgress(fileName, event))
            .send((err, data) => {
                if (err) {
                    this.uploadErrorHandler(err, data, observer);
                } else {
                    s3.listObjects({ Bucket }, (err, data) => {
                        if (err) {
                            this.uploadErrorHandler(err, data, observer);
                        } else {
                            observer.next(data);
                        }
                    });
                }
            });
    }

    private uploadErrorHandler(err: any, data: any, observer: Subscriber<any>) {
        if (err.code === 'RequestAbortedError') {
            console.warn(err.message);
        } else {
            console.error('there was an error uploading your file', err);
            console.warn('data', data);
        }
        observer.error(err);
    }

    private calcProgress(fileName: string, event: AWS.S3.ManagedUpload.Progress) {
        let dif = this.progressMap.get(fileName) ? (event.loaded - this.progressMap.get(fileName)) : event.loaded;
        this.progressMap.set(fileName, event.loaded);
        this.totalLoaded += dif;
        this.progress$.next(`${(this.totalLoaded / this.totalSize) * 100}`);
    }
}
import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'tag-list',
    templateUrl: './tag-list.component.html'
})
export class TagListComponent {
    @Input() data;
    @Input() tagsCount;
    @Input() isPreviewMode;
    @Input() removeable: boolean;
    @Output() removed = new EventEmitter();
    tags;
    length: any;

    removeTag(index) {
        this.data.splice(index, 1);
        this.removed.emit(this.data);
    }

    copy(value) {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };
}
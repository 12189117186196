import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

import { AlertDialog } from 'app/modals/alert/alert.modal';
import { BoolDialog } from 'app/modals/bool/bool.modal';
// import { SearchIdDialog } from 'app/modals/search-id/search-id.modal'; // TODO
// import { DeleteIdsDialog } from 'app/modals/delete-ids/delete-ids.modal'; // TODO
// import { UploadVideoDialog } from 'app/modals/upload-video/upload-video.modal'; // TODO
// import { DisplayFailedsDialog } from 'app/modals/display-faileds/display-faileds.modal'; // TODO
import { CreateCollectionDialog } from 'app/modals/create-collection/create-collection.modal';
import { AWSError } from 'aws-sdk';

@Injectable()

export class ModalService {

    constructor(private dialog: MatDialog) { }

    alert(data: { body: string | AWSError, title?: string }): MatDialogRef<any> {
        return this.dialog.open(AlertDialog, {
            width: '350px',
            data
        });
    }

    bool(data: { body: string, title?: string }): MatDialogRef<any> {
        return this.dialog.open(BoolDialog, {
            width: '350px',
            data
        });
    }

    createCollection(): MatDialogRef<any> {
        return this.dialog.open(CreateCollectionDialog, {
            disableClose: true,
            width: '350px'
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AgmCoreModule } from '@agm/core';
import {
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatSelectModule,
    MatDividerModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatChipsModule,
} from '@angular/material';

import { Ng2PanZoomModule } from 'ng2-panzoom';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// pipes
import { I18nPipe } from 'app/pipes/i18n.pipe';

// directives
import { EllipsisOverflowDirective } from 'app/directives/ellipsis-overflow.directive';

// components
import { GridComponent } from 'app/components/grid/grid.component';
import { BusyIndicatorComponent } from 'app/components/busy-indicator/busy-indicator.component';
import { InputWithErrorComponent } from 'app/components/input-with-error/input-with-error.component';
import { SimpleSelectComponent } from 'app/components/simple-select/simple-select.component';
import { AdvancedSelectComponent } from 'app/components/advanced-select/advanced-select.component';
import { ZoomComponent } from 'app/components/zoom/zoom.component';
import { TagsFilterComponent } from 'app/components/tags-filter/tags-filter.component';
import { TagListComponent } from 'app/components/tag-list/tag-list.component';

@NgModule({
    declarations: [
        I18nPipe,
        GridComponent,
        BusyIndicatorComponent,
        InputWithErrorComponent,
        SimpleSelectComponent,
        AdvancedSelectComponent,
        EllipsisOverflowDirective,
        ZoomComponent,
        TagsFilterComponent,
        TagListComponent,
    ],
    exports: [
        I18nPipe,
        FormsModule,
        RouterModule,
        CommonModule,
        MatCardModule,
        MatIconModule,
        GridComponent,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatSelectModule,
        MatDialogModule,
        MatDividerModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTableModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatChipsModule,
        InfiniteScrollModule,
        BusyIndicatorComponent,
        InputWithErrorComponent,
        SimpleSelectComponent,
        AdvancedSelectComponent,
        MatProgressSpinnerModule,
        EllipsisOverflowDirective,

        OwlNativeDateTimeModule,
        OwlDateTimeModule,

        AgmCoreModule,

        Ng2PanZoomModule,
        ZoomComponent,
        TagsFilterComponent,
        TagListComponent,
        NgxMatSelectSearchModule,
    ],
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        MatCardModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        MatSelectModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatDividerModule,
        MatTooltipModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatCheckboxModule,
        MatChipsModule,
        NgxMatSelectSearchModule ,
        OwlNativeDateTimeModule,
        OwlDateTimeModule,

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB5KD2p9JbA7LpdZrASK5O93VjXjON4ZGI'
        }),

        Ng2PanZoomModule,
        NgxMatSelectSearchModule,
    ]
})
export class SharedModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPage } from 'app/pages/login/login.page';
import { LoginResolver } from 'app/resolvers/login.resolver';

const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginPage,
        canActivate: [LoginResolver]
    },
    {
        path: 'sys',
        loadChildren: 'app/modules/sys.module#SysModule' 
    },
    {
        path: '**',
        redirectTo: 'login' 
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }

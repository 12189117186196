import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { DataService } from 'app/services/data.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'edit-tags',
    templateUrl: './edit-tags.modal.html',
    styleUrls: ['./edit-tags.modal.scss']
})
export class EditTagsDialog {
    private keySubject: Subject<string> = new Subject();
    private rowIndexSubject: Subject<number> = new Subject();
    tags = JSON.parse(JSON.stringify(this.data.tags));
    filteredKeys = [];
    filteredValues = [];
    DEBOUNCE_TIME = 350;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<EditTagsDialog>,
        private dataService: DataService) {
    }

    ngOnInit() {
        // A solution when there's no form
        this.keySubject.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe(key => this.getTagKeys(key));

        // Sent the index in order to filter by key and val 
        this.rowIndexSubject.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe(index => this.getTagValues(index));
    }
    cancel() {
        this.dialogRef.close('cancel');
    }

    deleteTag(index) {
        this.tags = this.tags.filter((t, idx) => idx !== index);
    }

    newTag() {
        if (!this.tags) {
            this.tags = [];
        }
        this.tags.push({ key: null, value: null });
    }

    onKeyChange(searchKey: string) {
        this.keySubject.next(searchKey);
    }

    onValueChange(index) {
        this.rowIndexSubject.next(index);
    }
    getTagKeys(key) {
        this.dataService.api({
            type: 'getTagKeys',
            disableBI: true,
            data: {
                keyName: key
            }
        }).subscribe(res => {
            this.filteredKeys = res.keyNames;
        });
    }

    getTagValues(index) {
        if (!this.tags[index].key) return;

        this.dataService.api({
            type: 'getTagValues',
            disableBI: true,
            data: {
                keyName: this.tags[index].key,
                valueName: this.tags[index].value,
            }
        }).subscribe(res => {
            this.filteredValues = res.valueNames;
        });
    }

    submit() {
        // Send only tags which are not empty
        this.tags = this.tags.filter(tags => !!tags.key && !!tags.value);
        const { entityId, entityType } = this.data;
        this.dataService.api({
            type: 'setTags',
            data: {
                entityId: entityId.toString(),
                entityType,
                tags: this.tags,
            }
        }).subscribe(() => this.dialogRef.close('success'));
    }

    copy(value) {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };
}


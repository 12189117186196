import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { FormService } from 'app/services/form.service';
import { UploadFilesService } from 'app/services/upload-files.service';
import { DataService } from 'app/services/data.service';

@Component({
    selector: 'video-batch',
    templateUrl: './video-batch.modal.html',
    styleUrls: ['./video-batch.modal.scss']
})

export class VideoBatchDialog {

    form: FormGroup;
    selectedCollectionKey;
    collections;
    collectionName: any;
    collectionKey: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<VideoBatchDialog>,
        private uploadFilesService: UploadFilesService,
        private dataService: DataService,
        private formBuilder: FormBuilder,
        private formService: FormService) { }

    ngOnInit() {
        this.getAllCollections();
        this.setForm();
    }

    private setForm() {
        this.form = this.formBuilder.group({
            collection: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            labelJob: false,
            almoniJob: false,
            recordStartTime: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            recordStopTime: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            jobName: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }]
        });
    }

    cancel() {
        this.dialogRef.close('cancel');
    }

    submit() {
        const formData = this.form.value;
        this.collectionKey = formData.collection.collectionKey
        this.collectionName = formData.collection.collectionName
        let apiCfg;
        let data;
        data = {
            jobName: formData.jobName,
            recordStartTime: formData.recordStartTime,
            recordStopTime: formData.recordStopTime,
            filePath: this.data.file.Prefix,
            labelJob: formData.labelJob,
            almoniJob: formData.almoniJob
        };
        this.collectionKey === 'group' ? data.collectionGroup = this.collectionName : data.collectionKey = this.collectionKey
        if (!this.data.isGoogle) {
            apiCfg = {
                type: 'sendVideoBatch',
                data: data
            }
        } else {
            data = {
                id: this.data.file.id,
                batchName: formData.jobName,
                recordStartTime: formData.recordStartTime,
                recordStopTime: formData.recordStopTime,
                batchType: this.data.batchType,
                labelJob: formData.labelJob,
                almoniJob: formData.almoniJob
            }
            this.collectionKey === 'group' ? data.collectionGroup = this.collectionName : data.collectionKey = this.collectionKey
            apiCfg = {
                type: 'sendBatchToGoogle',
                data: data
            }
        }
        this.dataService.api(apiCfg).subscribe(() => {
            this.dialogRef.close('success');
        });
    }

    getAllCollections() {
        this.dataService.api({
            type: 'getAllCollections'
        }).subscribe(res => {
            this.collections = res;
        });
    }
}
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs/internal/Subscription';

import { ProcessResult } from 'app/interfaces/server.interface';
import { GridCfg } from 'app/components/grid/grid.component';
import { I18nPipe } from 'app/pipes/i18n.pipe';

@Component({
    selector: 'item-process-result',
    templateUrl: './item-process-result.modal.html',
    styleUrls: ['./item-process-result.modal.scss']
})

export class ItemProcessResultsDialog {

    gridCfg: GridCfg;
    processResults: ProcessResult[];
    ITEMS_TO_DISPLAY = 40;
    filters = this.data.filters;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private i18n: I18nPipe) { }

    ngOnInit() {
        this.setGridCfg();
    }

    onSortChanged(sort) {
        if (!this.filters)
            this.filters = [];
        this.filters.sortData = sort;
    }

    private setGridCfg() {
        this.gridCfg = {
            infiniteScroll: true,
            gridDataKey: 'items',
            noDataMsg: 'processResults.noData',
            api: {
                type: 'getJobsResult',
                data: {
                    collectionKey: this.data.collectionKey,
                    direction: this.data.sortData.direction,
                    distinct: false,
                    withImagesUrls: false,
                    endDate: this.data.endTime,
                    minConfidence: this.data.confidence,
                    itemsInPage: this.ITEMS_TO_DISPLAY,
                    pageNumber: 1,
                    civilId: this.data.civilId,
                    sortBy: this.data.sortData.field,
                    jobName: this.data.jobName,
                    startDate: this.data.startTime,
                    batchName: this.data.batchName,
                }
            },
            columns: [{
                title: 'processResults.jobName',
                field: 'jobName',
                sortable: true,
                width: 35
            }, {
                title: 'processResults.confidence',
                field: 'confidence',
                sortable: true,
                width: 20
            }, {
                title: 'processResults.realTime',
                field: 'universalTime',
                sortable: true,
                cssStyle: { 'direction': 'ltr', 'text-align': 'right' },
                width: 25
            }, {
                title: 'processResults.videoTime',
                field: 'videoTime',
                sortable: true,
                width: 20
            }],
            onSortChanged: this.onSortChanged

        };
    }
}
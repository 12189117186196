import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { FormService } from 'app/services/form.service';
import { DataService } from 'app/services/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'upload-youtube-video',
    templateUrl: './upload-youtube-video.modal.html',
    styleUrls: ['./upload-youtube-video.modal.scss']
})

export class UploadYoutubeVideoDialog {
    form: FormGroup = this.formBuilder.group({
        youtubeUrl: [null, {
            validators: [
                this.formService.validators.requiredValue
            ]
        }],
        jobName: [null, {
            validators: [
                this.formService.validators.requiredValue
            ]
        }],
        useYoutubeDesc: '',
        recordStartTime: [null, {
            validators: [
                // this.formService.validators.requiredValue
            ]
        }],
        recordStopTime: [null, {
            validators: [
                // this.formService.validators.requiredValue
            ]
        }],
        labelJob: false,
        almoniJob: false
    });
    ngDestroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<UploadYoutubeVideoDialog>,
        private dataService: DataService,
        private formBuilder: FormBuilder,
        private formService: FormService) { }

    ngOnInit() {
        this.form.get('useYoutubeDesc').valueChanges.pipe(takeUntil(this.ngDestroy$)).subscribe(v => {
            if (v) {
                this.form.get('jobName').disable();
            } else {
                this.form.get('jobName').enable();
            }
        });
    }

    upload() {
        const formData = this.form.value;
        this.dataService.api({
            type: 'uploadYoutubeVideo',
            data: {
                collectionKey: this.data.collection.collectionKey,
                youtubeUrl: formData.youtubeUrl,
                jobName: !formData.useYoutubeDesc ? formData.jobName : null,
                recordStartTime: formData.recordStartTime,
                recordStopTime: formData.recordStopTime,
                labelJob: formData.labelJob,
                almoniJob: formData.almoniJob
            },
        }).subscribe(res => {
            this.closeDialog('success');
        });
    }

    cancel() {
        this.closeDialog('cancel');
    }

    private closeDialog(val?: string) {
        this.dialogRef.close(val);
    }
}
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataService } from 'app/services/data.service';

@Component({
  selector: 'orev-delete-civil-ids',
  templateUrl: './delete-civil-ids.modal.html',
  styleUrls: ['./delete-civil-ids.modal.scss']
})
export class DeleteCivilIdsDialog {
  civilIds = []
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteCivilIdsDialog>,
    private dataService: DataService) { }

  ngOnInit() {
    this.data.civilIds.forEach((person, i) => {
      if (person.civilId !== '') {
        this.civilIds[i] = person.civilId
      }
    });
  }

  deleteIds() {
    this.dataService.api({
      type: 'deleteId',
      data: {
        collectionKey: this.data.collectionKey,
        civilIds: this.civilIds
      }
    }).subscribe(() => this.dialogRef.close('success'));
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs/internal/Observable';
import { Subscriber } from 'rxjs/internal/Subscriber';
import * as AWSCognito from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';

import { awsConfigMap } from 'app/maps/aws-config.map';
import { I18nPipe } from 'app/pipes/i18n.pipe';
import { BusyIndicatorService } from './busy-indicator.service';
import { SetPasswordDialog } from 'app/modals/set-password/set-password-modal';
import { MFADialog } from 'app/modals/mfa/mfa.modal';
import { AuthService } from './auth.service';
import { TokensService } from './tokens.service';

@Injectable()
export class AwsAuthService {

    private authenticationDetails: AWSCognito.AuthenticationDetails;
    private cognitoUser: AWSCognito.CognitoUser;
    userPool = new AWSCognito.CognitoUserPool({
        UserPoolId: awsConfigMap.userPoolId,
        ClientId: awsConfigMap.clientId
    });
    constructor(private i18n: I18nPipe,
        private dialog: MatDialog,
        private busyIndicator: BusyIndicatorService,
        private tokensService: TokensService) { }

    getAwsToken(Username: string, Password: string): Observable<any> {
        const that = this; // We send this scope to 'completeNewPasswordChallenge' function
        const i18n = this.i18n;
        return new Observable(observer => {
            this.authenticationDetails = new AWSCognito.AuthenticationDetails({
                Username,
                Password
            });
            this.cognitoUser = new AWSCognito.CognitoUser({
                Username,
                Pool: this.userPool
            });
            this.cognitoUser.authenticateUser(this.authenticationDetails, {
                onSuccess: result => this.onLoginSuccess(observer, result),
                onFailure: err => { this.onLoginFailed(observer, err) },
                mfaRequired: (challengeName, challengeParameters) => {
                    // Stopping the BI Manually because we open modal
                    that.busyIndicator.decrease();
                    this.dialog.open(MFADialog, {
                        width: '350px',
                    }).afterClosed().subscribe(res => {
                        if (res.verificationCode) {
                            this.cognitoUser.sendMFACode(res.verificationCode, {
                                onSuccess: result => this.onLoginSuccess(observer, result),
                                onFailure: err => this.onLoginFailed(observer, err)
                            });
                        }
                    })
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    // Stopping the BI Manually because we open modal
                    that.busyIndicator.decrease();
                    that.dialog.open(SetPasswordDialog, {
                        width: '350px',
                    }).afterClosed().subscribe(res => {
                        if (res.password) {
                            that.cognitoUser.completeNewPasswordChallenge(res.password, {}, this);
                        }
                    });
                }
            });
        });
    }

    // Refreshes access token
    refreshToken(): Observable<any> {
        return new Observable(observer => {
            this.refreshCredentials().subscribe(() => {
                console.log('[AwsAuthService] - Trying to refresh token', new Date());
                const currentUser = this.userPool.getCurrentUser();
                currentUser && currentUser.getSession((err, session) => {
                    if (err) observer.error(err);
                    else {
                        const cognitoIsp = new AWS.CognitoIdentityServiceProvider();
                        const params = {
                            AuthFlow: 'REFRESH_TOKEN',
                            ClientId: awsConfigMap.clientId,
                            AuthParameters: {
                                'REFRESH_TOKEN': session.getRefreshToken().getToken()
                            }
                        }

                        cognitoIsp.initiateAuth(params, (err, data) => { // Refreshes access token
                            if (err) observer.error(err); // we could be logged out here
                            else {
                                this.tokensService.accessToken = data.AuthenticationResult.AccessToken;
                                observer.next(data);
                            };
                        });
                    }
                });
            });
        });
    }

    /* 
        Refreshes access token and sets cognito config according to session
     */
    private refreshCredentials(): Observable<any> {
        return new Observable(observer => {
            this.userPool.getCurrentUser().getSession((err, session) => {
                if (err) observer.error(err);
                else {

                    // We init a minimal object to clearCacheId
                    let credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: awsConfigMap.identityPoolId,
                    });

                    // Clearing cacheId on purpose (avoid switching user bug)
                    credentials.clearCachedId();

                    // The real credentials object 
                    credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: awsConfigMap.identityPoolId,
                        Logins: {
                            [awsConfigMap.loginsKey]: session.getIdToken().getJwtToken()
                        },
                    });

                    //Configures the AWS service and initial authorization
                    AWS.config.update({
                        region: awsConfigMap.region,
                        credentials
                    });

                    credentials.refresh(err => {
                        if (err) observer.error(err);
                        else observer.next(true);
                    });
                }
            });
        });
    }

    signOut() {
        this.userPool.getCurrentUser() && this.userPool.getCurrentUser().signOut();
    }

    private onLoginSuccess(observer: Subscriber<any>, result: AWSCognito.CognitoUserSession) {
        observer.next(result);
    }

    private onLoginFailed(observer: Subscriber<any>, err: any) {
        console.error(err);
        observer.error(err);
    }
}

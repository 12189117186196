/**
 * This service handles user authentication. It sets user as logged-in
 * or logged-out and it saves the user-data (user-role).
 */
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { TokensService } from 'app/services/tokens.service';
import { BusyIndicatorService } from 'app/services/busy-indicator.service';
import { LocalStorageService } from './local-storage.service';
import { MatDialog } from '@angular/material';
import { TimingEventsService } from './timingEvents.service';
import { take } from 'rxjs/operators';

@Injectable()
export class AuthService {
    /**
     * The logged-in status as an observable or as a snapshot.
     */
    readonly isLoggedIn$ = new BehaviorSubject<boolean>(false);
    get isLoggedIn() { return this.isLoggedIn$.getValue(); }

    /**
     * Signifies whether the service has finished initializing the user state.
     */
    readonly ready$ = new BehaviorSubject<boolean>(false);

    /**
     * The user-data as an observable or as a snapshot.
     */
    private _user$ = new BehaviorSubject<string>(null);
    user$ = this._user$.asObservable();
    get user(): string { return this._user$.getValue(); }

    readonly isAdmin$ = new BehaviorSubject<boolean>(false);
    get isAdmin() { return this.isAdmin$.getValue(); }

    constructor(
        private router: Router,
        private awsAuthService: AwsAuthService,
        private tokensService: TokensService,
        private busyIndicator: BusyIndicatorService,
        private localStorageService: LocalStorageService,
        private timingEventsService: TimingEventsService,
        private matDialog: MatDialog) {
        this.init();    
    }

    login(token: AWSCognito.CognitoUserSession): Observable<any> {
        return new Observable(observer => {
            this.setTokens(token).then(() => {
                this.setMetaData(token).then(() => {
                    this.isLoggedIn$.next(true);
                    observer.next(true);
                    observer.complete();
                });
            });
        });
    }

    /**
    * Gets a new refresh token every designated time 
    */
    initTokenGetter() {
        const tokenGetterIntervalTime = 300 * 1000;
        console.log('[TokenGetter] - initted');
        const tokenGetterInterval = setInterval(() => {
            console.log('[TokenGetter] - try to get a new token', new Date());
            // Getting new refresh token before api call 
            this.awsAuthService.refreshToken().pipe(take(1)).subscribe(res => {
                console.info(`[TokenGetter] - got a new token after ${tokenGetterIntervalTime * 1.66666667 * 10 ** -5 | 0} mins`, new Date());
            }, err => this.setLoggedOut());
        }, tokenGetterIntervalTime);

        this.timingEventsService.pushInterval(tokenGetterInterval);
    }

    setLoggedOut(): void {
        this.awsAuthService.signOut();
        this.isLoggedIn$.next(false);
        this._user$.next(null);  // clears the user model
        this.ready$.next(true);
        this.isAdmin$.next(false);
        this.tokensService.clearTokens();
        this.localStorageService.clear();
        this.busyIndicator.decrease();
        this.timingEventsService.clearAll();
        this.matDialog.closeAll();
        this.router.navigate(['/login']);
    }

    init() {
        if (!this.awsAuthService.userPool.getCurrentUser()) {
            this.setLoggedOut();
            return;
        }
        /**
         * On startup, check local-storage if there is a saved user.
         * IF        No user is saved, set logged-out.
         * ELSE IF   Token has expired, refresh token.  
         * ELSE      The token is valid, set meta data.
         */
        this.awsAuthService.userPool.getCurrentUser().getSession((err, session) => {
            if (err) this.setLoggedOut();
            else {
                this.awsAuthService.refreshToken().pipe(take(1)).subscribe(() => {
                    this.initTokenGetter();
                    this.setExistingUserMetaData(session).then(() =>
                        this.ready$.next(true));
                }, err => {
                    this.setLoggedOut();
                });
            }
        });
    }

    private setTokens(token: AWSCognito.CognitoUserSession): Promise<any> {
        return new Promise(resolve => {
            // The new way of setting token for regular api and cognito
            this.tokensService.accessToken = token.getAccessToken().getJwtToken();
            this.tokensService.refreshToken = token.getRefreshToken().getToken();
            resolve();
        });
    }

    private setMetaData(token: AWSCognito.CognitoUserSession): Promise<any> {
        return new Promise(resolve => {
            const decodedToken = token.getAccessToken().decodePayload();
            const user = decodedToken.username;
            this._user$.next(user);  // refreshes the user model
            const groups = decodedToken['cognito:groups'];
            this.isAdmin$.next(groups &&
                !!groups.includes('Admins'));
            this.isLoggedIn$.next(true);
            resolve();
        });
    }

    private async setExistingUserMetaData(token: AWSCognito.CognitoUserSession) {
        await this.setTokens(token);
        await this.setMetaData(token);
        this.isLoggedIn$.next(true);
    }
}
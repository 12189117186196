import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { map, tap, catchError, flatMap, take } from 'rxjs/operators';
import { of as obsOf } from 'rxjs/internal/observable/of';
import { fromPromise as obsFromPromise } from 'rxjs/internal/observable/fromPromise';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { template } from 'lodash';

import { apiMap } from 'app/maps/api.map';
import { I18nPipe } from 'app/pipes/i18n.pipe';
import { EventService } from 'app/services/event.service';
import { ModalService } from 'app/services/modal.service';
import { BusyIndicatorService } from 'app/services/busy-indicator.service';
import * as args from 'app/interfaces/apiCfg.interface';
import { TimerService } from 'app/services/timer.service';
import { TokensService } from 'app/services/tokens.service';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { AuthService } from './auth.service';

@Injectable()
export class DataService {
    _defaultHeaders = { 'Content-Type': 'application/json' };
    isLoggedIn: boolean = false;

    constructor(
        private i18n: I18nPipe,
        private http: HttpClient,
        private eventService: EventService,
        private modalService: ModalService,
        private biService: BusyIndicatorService,
        private timerService: TimerService,
        private tokensService: TokensService,
        private awsAuthService: AwsAuthService,
        private authService: AuthService) { }

    /**
     * Communicates with the server using HTTP methods.
     */
    api(cfg: args.ApiCfg): Observable<any> {
        cfg = Object.assign({}, args.API_CFG_DEFAULTS, cfg);
        const apiMapItem = apiMap[cfg.type];
        if (!apiMapItem)
            throw 'no such api type in apiMap: ' + cfg.type;

        if (apiMapItem.mockup)
            return obsOf(apiMapItem.mockup);

        this.increaseBI(cfg);

        const params = this.getParams(cfg.queryParams);
        const url = this.getUrl(apiMapItem.url + params, cfg);
        const responseType = apiMap[cfg.type].responseType ? apiMap[cfg.type].responseType : 'text';
        // return this.timerService.resetActivityTimer().pipe(
        // flatMap(() => {
        const response$ = this.http.request(apiMapItem.method, url, {
            body: cfg.data,
            headers: new HttpHeaders(
                Object.assign(
                    {},
                    this._defaultHeaders,
                    apiMapItem.headers || {},
                    cfg.headers || {},
                    { Authorization: this.tokensService.accessToken })
            ),
            observe: 'response',
            responseType: responseType,
            withCredentials: false,
        });
    this.timerService.resetActivityTimer();
        return this.responseHandler(response$, cfg);
    }

    /**
     * Repeats an original API request and emits to the original api returned-subject
     */
    repeatApi(cfg: args.ApiCfg, originalSubject): void {
        this.api(cfg).subscribe(
            (res) => {
                originalSubject.next(res);
                originalSubject.complete();
            },
            (err) => {
                originalSubject.error(err);
            }
        );
    }

    getUrl(urlTemplate: string, cfg: args.ApiCfg) {
        if (!cfg.urlParams) {
            cfg.urlParams = {};
        }
        return template(urlTemplate)(cfg);
    }

    errorHandler(err: HttpErrorResponse, cfg: args.ApiCfg): Observable<any> | Subject<any> {
        this.decreaseBI(cfg);
        let errorMsg: string;
        const errorMsgTemplate = this.safeJSONparse(err.error);

        if (this.isTokenExpiredError(err)) {
            // get a new token
            this.awsAuthService.refreshToken().pipe(take(1)).subscribe(() => {
                console.log('[API - refreshed token after expiration error');
                console.log(cfg, 'cfg after error - request again')

            }, err => {
                console.log('err refreshing token')
                this.authService.setLoggedOut()
            });
        }

        if (cfg.disableErrorHandler) {
            return throwError(err);
        } else {
            if (errorMsgTemplate.msg) {
                errorMsg = errorMsgTemplate.msg
            } else {
                errorMsg = this.i18n.transform(`serverErrors.default${err.status}`);
            }
        }

        return this.modalService.alert({
            body: errorMsg
        }).afterClosed().pipe(flatMap(() => throwError(err)));
    }

    increaseBI(cfg) {
        if (!cfg.disableBI) this.biService.increase();
    }

    decreaseBI(cfg) {
        if (!cfg.disableBI) this.biService.decrease();
    }

    isTokenExpiredError = (err) => err.status === 400;

    safeJSONparse = (val) => {
        try {
            return JSON.parse(val);
        } catch (e) {
            return val;
        }
    }

    private responseHandler(res: Observable<any>, cfg: args.ApiCfg): Observable<any> {
        return res.pipe(
            tap(res => {
                this.decreaseBI(cfg);
                if (apiMap[cfg.type].responseType === 'blob') {
                    this.downloadFile(res);
                }
            }),
            map(res => {
                try {
                    // This will fail if the response is a string.
                    return JSON.parse(res.body);
                } catch (err) {
                    return res.body;
                }
            }),
            catchError(err => this.errorHandler(err, cfg))
        );
    }

    /**
     * returns an encoded param string
     */
    private getParams(queryParams: any): string {
        if (!queryParams) return '';
        let params = '';
        for (let paramKey in queryParams) {
            queryParams[paramKey] = encodeURIComponent(queryParams[paramKey]);
            params += paramKey + '=' + queryParams[paramKey] + '&';
        }
        params = '?' + params.substr(0, params.length - 1);
        return params;
    }

    private downloadFile(data: any) {
        const blob = new Blob([data.body], { type: 'text/csv' });
        const fileName = data.headers.get('content-disposition').split(";")[1].trim().split("=")[1].replace(/"/g, '');

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob);
            navigator.msSaveBlob(blob, fileName);
        } else {
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }
}

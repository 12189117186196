import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PanZoomConfig, PanZoomAPI, PanZoomModel } from 'ng2-panzoom';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'zoom',
    templateUrl: './zoom.component.html',
    styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent {
    ngDestroy$: Subject<boolean> = new Subject<boolean>();
    panZoomConfig: PanZoomConfig = new PanZoomConfig;
    panZoomAPI: PanZoomAPI;

    @Input() width?;
    @Input() height?;
    @Output() zoomed?: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.panZoomConfig = {
            ...this.panZoomConfig,
            zoomLevels: 20,
            scalePerZoomLevel: 2, // 2
            freeMouseWheel: false,
            invertMouseWheel: true,
            // panOnClickDrag: false,
            keepInBounds: true,
        };

        this.panZoomConfig.api.pipe(takeUntil(this.ngDestroy$)).subscribe((api: PanZoomAPI) => this.panZoomAPI = api);
        this.panZoomConfig.modelChanged.pipe(takeUntil(this.ngDestroy$)).subscribe((model: PanZoomModel) => this.zoomed.emit(model));
    }

    ngOnDestroy() {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
    }

    zoomIn() {
        console.log('in')
        this.panZoomAPI.zoomIn();
    }

    zoomOut() {
        this.panZoomAPI.zoomOut();
    }

    resetZoom() {
        this.panZoomAPI.resetView();
    }
}
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class EventService {
    private subjects = {};

    constructor(private router: Router) {
        // 'NAVIGATION_END': router navigation-end event
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.emit('NAVIGATION_END', val);
            }
        });
    }

    observe(key: string): Subject<any> {
        if (!this.subjects[key]) {
            this.subjects[key] = new Subject();
        }
        return this.subjects[key];
    }

    emit(key: string, val?: any): void {
        this.observe(key).next(val || null);
    }

    dispose(key: string): void {
        if (this.subjects[key]) {
            this.subjects[key].complete();
            delete this.subjects[key];
        }
    }
}

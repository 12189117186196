import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FormService } from 'app/services/form.service';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { awsConfigMap } from 'app/maps/aws-config.map';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { ModalService } from 'app/services/modal.service';
import { BusyIndicatorService } from 'app/services/busy-indicator.service';
import { AuthService } from 'app/services/auth.service';
import { TimerService } from 'app/services/timer.service';

@Component({
    selector: 'create-user',
    templateUrl: './create-user.modal.html',
    styles: [`
        mat-dialog-actions {
            justify-content: space-around;
        }
    `]
})

export class CreateUserDialog {

    form: FormGroup;
    cognitoService
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private dialogRef: MatDialogRef<CreateUserDialog>,
        private modalService: ModalService,
        private awsAuthService: AwsAuthService,
        private busyIndicator: BusyIndicatorService,
        private authService: AuthService,
        private timerService: TimerService) { }

    ngOnInit() {
        this.setForm();
    }

    createUser() {
        this.timerService.resetActivityTimer();
        this.cognitoService = new CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: awsConfigMap.region
        });
        if (this.form.valid) {
            const formData = this.form.value;
            this.busyIndicator.increase();
            this.cognitoService.adminCreateUser({
                Username: formData.username,
                UserPoolId: awsConfigMap.userPoolId,
                TemporaryPassword: formData.temporaryPassword,
                UserAttributes: [
                    { Name: 'email', Value: formData.email },
                    { Name: 'phone_number', Value: formData.phoneNumber },
                    { Name: 'email_verified', Value: 'true' },
                    { Name: 'phone_number_verified', Value: 'true' }
                ]
            }, (err, data) => {
                this.busyIndicator.decrease();
                if (err) {
                    this.modalService.alert({
                        body: err,
                    });
                } else {
                    this.addGroupAndMFA();
                }
            });
        }
    }

    private addGroupAndMFA = () => {
        this.busyIndicator.increase();
        this.cognitoService.adminSetUserSettings({
            Username: this.form.value.username,
            UserPoolId: awsConfigMap.userPoolId,
            MFAOptions: [
                {
                    AttributeName: "phone_number",
                    DeliveryMedium: "SMS"
                }
            ],

        }, (err, data) => {
            this.busyIndicator.decrease();
            if (err) {
                this.modalService.alert({
                    body: err,
                });
            } else {
                this.enableMFA();
            }
        });
    }
    // private setUserOption =
    private enableMFA = () => {
        this.busyIndicator.increase();
        this.cognitoService.adminSetUserMFAPreference({
            Username: this.form.value.username,
            UserPoolId: awsConfigMap.userPoolId,
            SMSMfaSettings: {
                Enabled: true,
                PreferredMfa: true,
            },
        }, (err, data) => {
            this.busyIndicator.decrease();
            if (err) {
                this.modalService.alert({
                    body: err,
                });
            } else {
                console.log('mfa was enabled');
                this.addToUsersGroup();
            }
        });
    }

    private addToUsersGroup = () => {
        let params = {
            GroupName: 'Users',
            UserPoolId: awsConfigMap.userPoolId,
            Username: this.form.value.username
        };
        this.cognitoService.adminAddUserToGroup(params, (err, data) => {
            if (err) {
                this.modalService.alert({
                    body: err,
                });
            } else {
                console.log('group was set ("Users")');
                this.dialogRef.close('success');
            };
        });
    }

    private setForm() {
        // There is no validation for the fields, maybe add in the future
        const { passwordPattern, emailPattern, phonePattern, requiredPassword, requiredValue } = this.formService.validators;
        this.form = this.formBuilder.group({
            username: [null, { validators: [requiredValue] }],
            temporaryPassword: [null, { validators: [requiredPassword, passwordPattern] }],
            email: [null, { validators: [requiredValue, emailPattern] }],
            phoneNumber: [null, { validators: [requiredValue, phonePattern] }]
        });
    }
}
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { FormService } from 'app/services/form.service';
import { UploadFilesService } from 'app/services/upload-files.service';
import { DataService } from 'app/services/data.service';

@Component({
    selector: 'image-batch',
    templateUrl: './image-batch.modal.html',
    styleUrls: ['./image-batch.modal.scss']
})

export class ImageBatchDialog {

    form: FormGroup;
    selectedCollectionKey;
    collections;
    showTranscriptionCheckbox;
    collectionName: any;
    collectionKey: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ImageBatchDialog>,
        private uploadFilesService: UploadFilesService,
        private dataService: DataService,
        private formBuilder: FormBuilder,
        private formService: FormService) { }

    ngOnInit() {
        this.getAllCollections();
        this.setForm();
        this.showTranscriptionCheckbox = !this.data.mode;

    }

    private setForm() {
        this.form = this.formBuilder.group({
            collection: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            description: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }],
            recordStartTime: [null, {
                validators: [
                    // this.formService.validators.requiredValue
                ]
            }],
            recordStopTime: [null, {
                validators: [
                    // this.formService.validators.requiredValue
                ]
            }],
            labelJob: false,
            almoniJob: false
        });
    }

    cancel() {
        this.dialogRef.close('cancel');
    }

    submit() {
        const formData = this.form.value;
        console.log('formData',formData);
        this.collectionKey = formData.collection.collectionKey
        this.collectionName = formData.collection.collectionName
        let data;``
        let apiCfg;
        data = {
            description: formData.description,
            batchType: (this.data.mode === 'index' ? 'index' : 'rk'),
            filePath: this.data.file.Prefix,
            recordStartTime: formData.recordStartTime,
            recordStopTime: formData.recordStopTime,
            labelJob: formData.labelJob,
            almoniJob: formData.almoniJob
        }
        this.collectionKey === 'group' ? data.collectionGroup = this.collectionName : data.collectionKey =  this.collectionKey;
        if (!this.data.isGoogle) {
            apiCfg = {
                type: 'imageBatchJob',
                data: data
            }
        } else {
            data = {
                id: this.data.file.id,
                batchType: this.data.batchType,
                batchName: formData.description,
                recordStartTime: formData.recordStartTime,
                recordStopTime: formData.recordStopTime,
                labelJob: formData.labelJob,
                almoniJob: formData.almoniJob
            }
            this.collectionKey === 'group' ? data.collectionGroup = this.collectionName : data.collectionKey = this.collectionKey;
            apiCfg = {
                type: 'sendBatchToGoogle',
                data: data
            }
        }
        console.log('apicnf', apiCfg)
        this.dataService.api(apiCfg).subscribe(() => {
            this.dialogRef.close('success');
        });
    }

    getAllCollections() {
        this.dataService.api({
            type: 'getAllCollections'
        }).subscribe(res => {
            this.collections = res;
        });
    }
}
import { Component, Inject, ViewChild, ElementRef, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GridCfg } from 'app/components/grid/grid.component';
import { PanZoomModel } from 'ng2-panzoom';
@Component({
    selector: 'images-item-process-result',
    templateUrl: './images-item-process-result.modal.html',
    styleUrls: ['./images-item-process-result.modal.scss']
})

export class ImagesItemProcessResultsDialog {
    rows = [];
    gridCfg: GridCfg;
    selectedItem;
    keyName = 'randomUuid';
    zoomLevel;

    @ViewChild('fullImage') fullImage: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cd: ChangeDetectorRef) { }

    ngOnInit() {
        this.setGridCfg();
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
    }

    private setGridCfg() {
        console.log('list - IMG JOBS extended RESULTS CALL')

        this.gridCfg = {
            infiniteScroll: true,
            gridDataKey: 'items',
            noDataMsg: 'processResults.noData',
            keyName: 'randomUuid',
            api: {
                type: 'getImageBatchesSingleResult',
                data: {
                    collectionKey: this.data.collectionKey,
                    imageKey: this.data.imageKey,

                    // Dummy params
                    itemsInPage: null,
                    pageNumber: 1,
                    sortBy: null,
                    direction: 'desc'
                }
            },
            columns: [{
                title: 'G.civilId',
                field: 'civilId',
                width: 20
            }, {
                title: 'processResults.confidence',
                field: 'confidence',
                width: 20
            }],
            onRowClick: this.handleRowClick,
            onDataFetch: this.handleDataFetched,
        };
    }

    handleDataFetched = (rows) => {
        this.rows = rows.map(row => {
            row.faceBox.style = {
                position: 'absolute',
                'top.%': row.faceBox.Top * 100,
                'left.%': row.faceBox.Left * 100,
                'width.%': row.faceBox.Width * 100,
                'height.%': row.faceBox.Height * 100,
                'border-style': 'solid',
                'border-color': this.getBoxColor(row),
                'border-width': this.zoomLevel > 2 ? '1px' : '2px',
                'opacity': this.zoomLevel > 2 ? 0.5 : 1,
                'cursor': 'pointer',
            }

            return row;
        });

        // [hack] - Angular doesn't recognize the rows obj change even with cloneDeep 
        this.cd.detectChanges();
    }

    private getBoxColor(row) {
        // 'unknown' => gray, selected => green, recognized => red, too_small => blue
        if (this.selectedItem &&
            this.keyName &&
            this.selectedItem[this.keyName] === row[this.keyName]) {
            return 'green';
        } else if (row.civilId === 'unknown') {
            return 'LightGray';
        } else if (row.civilId === 'too_small') {
            return 'blue';
        }

        return 'red';
    }

    selectImage = (item) => {
        this.handleRowClick(item);
    }

    handleRowClick = (item) => {
        this.selectedItem = { ...item };
        this.handleDataFetched(this.rows);
    }

    handleZoomed = (model: PanZoomModel) => {
        if (!Number.isInteger(model.zoomLevel)) return;
        this.zoomLevel = model.zoomLevel;
        this.handleDataFetched(this.rows);
    }
}
import { Injectable } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { AwsAuthService } from './aws-auth.service';

@Injectable()
export class TokensService {
    private _refreshToken;
    private _accessToken;

    get refreshToken() {
        return this._refreshToken;
    }

    get accessToken() {
        return this._accessToken;
    }

    set refreshToken(val) {
        this._refreshToken = val;
    }

    set accessToken(val) {
        this._accessToken = val;
    }

    constructor() { }

    clearTokens() {
        this._refreshToken = null;
        this._accessToken = null;
    }
}


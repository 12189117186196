/**
 * This is the global busy-indicator (BI). It observes the process-counter from BusyIndicatorService.
 * When the counter is positive, it shows itself. Otherwise, it is hidden.
 */
import { FormControl, FormGroupDirective, NgForm, FormGroup, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Component, Input } from '@angular/core';
import { startWith } from 'rxjs/operators';

import { errTypes } from 'app/maps/error-types';
import { I18nPipe } from 'app/pipes/i18n.pipe';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'advanced-select',
    templateUrl: './advanced-select.component.html',
    styles: [
        `mat-form-field {
            width: 100%;
        }`
    ]
})
/**
 * Select for array of strings (option = string)
 */
export class AdvancedSelectComponent {

    @Input() form: FormGroup;
    @Input() formCtrlName: string;
    @Input() keyName: string;
    @Input() valueName: string;
    @Input() options;
    @Input() placeholder: string;
    @Input() errorValidationData: any;

    errDisplay: string;
    formCtrl: AbstractControl;
    matcher = new MyErrorStateMatcher();

    constructor(private i18n: I18nPipe) { }

    ngOnInit() {
        this.formCtrl = this.form.get(this.formCtrlName);

        if (!this.formCtrl.value) {
            console.log(this.formCtrl);
        }

        this.formCtrl.valueChanges
            .pipe(startWith(this.formCtrl.value))
            .subscribe(() => this.renderErrors());     
    }

    private renderErrors() {
        this.errDisplay = '';
        if (!this.formCtrl.errors) return;
        
        errTypes.forEach(type => {
            if (this.formCtrl.hasError(type)) {
                this.errDisplay += this.i18n.transform('validation.' + type, this.errorValidationData);
            }
        });
    }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'bool-dialog',
    templateUrl: './bool.modal.html',
    styles: [`
        mat-dialog-actions {
            justify-content: space-around;
        }
    `]
})

export class BoolDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}
import { Injectable } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { AwsAuthService } from './aws-auth.service';
import { ModalService } from './modal.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TimingEventsService } from './timingEvents.service';

@Injectable()
export class TimerService {
    constructor(private modalService: ModalService,
        private awsAuthService: AwsAuthService,
        private authService: AuthService,
        private timingEventsService: TimingEventsService) { }

    modalTimeout;
    sessionTimeout;
    private sessionTimeoutTime = 3600 * 1000; // 60 mins
    private modalTimeoutTime = this.sessionTimeoutTime - 60 * 1000; // One minute before session timeout

    /**    
    * Timer for logging out after X inactive time
    */
    resetActivityTimer() {
            // clear modal and session timeout if there was any
            this.timingEventsService.clearTimeout(this.modalTimeout);
            this.timingEventsService.clearTimeout(this.sessionTimeout);

            console.log('[ActivityTimer] - init');
            this.modalTimeout = setTimeout(() => {
                this.modalService.alert({
                    body: 'inactivityDialog.noActivity',
                }).afterClosed().subscribe(res => {
                    if (res === 'ok') {
                        // Getting new refresh token
                        this.awsAuthService.refreshToken().pipe(take(1)).subscribe(
                            res => console.info('[ActivityTimer] - init timer + refreshed token', new Date()),
                            err => {
                                console.log('[ActivityTimer] - err refreshing token')
                                this.authService.setLoggedOut()
                            }
                        );
                    }
                })
            }, this.modalTimeoutTime);

            // When user will logout
            this.sessionTimeout = setTimeout(() => {
                console.log('[ActivityTimer] - logging out because of inactivity', new Date());
                this.authService.setLoggedOut();
            }, this.sessionTimeoutTime);
            this.timingEventsService.pushTimeout(this.modalTimeout);
            this.timingEventsService.pushTimeout(this.sessionTimeout);
    }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FormService } from 'app/services/form.service';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { awsConfigMap } from 'app/maps/aws-config.map';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { ModalService } from 'app/services/modal.service';
import { BusyIndicatorService } from 'app/services/busy-indicator.service';
import { AuthService } from 'app/services/auth.service';
import { TimerService } from 'app/services/timer.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'edit-user',
    templateUrl: './edit-user.modal.html',
    styles: [`
        mat-dialog-actions {
            justify-content: space-around;
        }
    `]
})

export class EditUserDialog {

    form: FormGroup;
    cognitoService = new CognitoIdentityServiceProvider({
        apiVersion: '2016-04-18',
        region: awsConfigMap.region
    });
    knowIfAdmin$ = new BehaviorSubject<boolean>(null);
    canSubmitForm$ = new BehaviorSubject<boolean>(null);
    ngDestroy$: Subject<boolean> = new Subject<boolean>();
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private dialogRef: MatDialogRef<EditUserDialog>,
        private modalService: ModalService,
        private awsAuthService: AwsAuthService,
        private busyIndicator: BusyIndicatorService,
        private authService: AuthService,
        private timerService: TimerService) { }

    ngOnInit() {
        this.isAdmin();
        this.knowIfAdmin$.pipe(takeUntil(this.ngDestroy$)).subscribe(res => {
            if (res !== null) this.setForm(res);
        });
    }

    ngOnDestroy() {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
    }

    submit() {
        this.form.get('isAdmin').value ? this.addToAdmin() : this.removeFromAdmin();

        this.canSubmitForm$.pipe(takeUntil(this.ngDestroy$)).subscribe(res => {
            if (res !== null) this.dialogRef.close('success');
        })
    }

    private setForm(isAdmin: boolean) {
        this.form = this.formBuilder.group({
            isAdmin,
        });
    }

    private addToAdmin = () => {
        this.timerService.resetActivityTimer()
        this.busyIndicator.increase();
        let params = {
            GroupName: 'Admins',
            UserPoolId: awsConfigMap.userPoolId,
            Username: this.data.user.username
        };
        this.cognitoService.adminAddUserToGroup(params, (err, data) => {
            this.busyIndicator.decrease();
            if (err) {
                this.modalService.alert({
                    body: err,
                });
            } else {
                console.log('group was set ("Admins")');
                this.canSubmitForm$.next(true);
            };
        });
    }

    private removeFromAdmin = () => {
        this.timerService.resetActivityTimer()
        this.busyIndicator.increase();
        const params = {
            GroupName: 'Admins',
            Username: this.data.user.username,
            UserPoolId: awsConfigMap.userPoolId,
        }
        this.cognitoService.adminRemoveUserFromGroup(params, (err, data) => {
            this.busyIndicator.decrease();
            if (err) {
                this.modalService.alert({
                    body: err,
                });
            } else {
                console.log('group was removed ("Admins")');
                this.canSubmitForm$.next(true);
            };
        });
    }

    private isAdmin = () => {
        this.timerService.resetActivityTimer()
        const params = {
            Username: this.data.user.username,
            UserPoolId: awsConfigMap.userPoolId,
        }
        this.cognitoService.adminListGroupsForUser(params, (err, data) => {
            if (err) {
                this.modalService.alert({
                    body: err,
                });
            } else {
                const isAdmin = data.Groups.map(g => g.GroupName).includes('Admins');
                this.knowIfAdmin$.next(isAdmin);
            }
        });
    }
}
import { SafeHtml } from '@angular/platform-browser';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataService } from 'app/services/data.service';

export interface GridColumn {
    title?: string;
    field?: string;
    cssStyle?: any;
    width?: number; // percent
    offset?: number; // percent
    cssClass?: string;
    sortField?: string;
    sortable?: boolean;
    onClick?: Function;
    sortDirection?: string;
    tooltip?: (data: any, field: string) => string | number;
    conditionalPointer?: (data: any, field: string) => boolean;
    formatter?: (data: any, field: string) => string | number | SafeHtml;
}

export interface GridCfg {
    api: {
        type: string;
        urlParams?: any;
        data?: {
            [field: string]: any; // optional field
            itemsInPage: number;
            pageNumber: number;
            sortBy: string; // field name
            direction: 'desc' | 'asc';
        };
        disableBI?: boolean;
    };
    wrapResultArray?: boolean;
    gridDataKey?: string; // The response's field for the grid data
    columns: Array<GridColumn>;
    infiniteScroll?: boolean;
    onRowClick?: Function;
    onIconClick?: Function;
    iconType?: string;
    keyName?: string;
    onDataFetch?: Function; // Gets the api data 
    onSortChanged?: Function;
    noDataMsg?: string;
    rowTooltipPosition?: 'after' | 'before' | 'above' | 'below' | 'left' | 'right';
    rowTooltip?: (data: any) => string | number;
}

@Component({
    selector: 'grid',
    templateUrl: 'grid.component.html',
    styleUrls: ['grid.component.scss']
})

export class GridComponent {

    _gridCfg: GridCfg;
    gridColWidth: number;
    showNoDataMsg: boolean;
    disableInfiniteScroll = false;
    lastPage = false;
    data: any[] = [];
    listHeight = {};
    sort = {};
    page = 1;

    
    @Input() set gridCfg(val) {
        this._gridCfg = val;
        val && this.initGrid();
    };
    get gridCfg() {
        return this._gridCfg;
    }

    @Input() selectedItemKey?;

    constructor(private dataService: DataService) { }

    onItemClicked(actionFnc: Function, item?: any) {
        if (event) {
            event.preventDefault ? event.preventDefault() : (event.returnValue = false);
            event.stopPropagation ? event.stopPropagation() : (event.cancelBubble = true);
        }

        actionFnc(item);
    }

    getColWidth(col): string {
        let width = col.width ? col.width : this.gridColWidth;
        return width + '%';
    }

    sortGrid(sortField: string, field: string): void {
        let f = sortField ? sortField : field;
        this.gridCfg.api.data.direction = null;
        this.gridCfg.api.data.sortBy = null;
        this.data = [];

        if (this.sort[f]) {
            if (this.sort[f].direction === 'asc') {
                this.sort[f].direction = 'desc'
            } else {
                this.sort[f].direction = 'asc'
            }
        } else {
            this.sort = {};
            this.sort[f] = {
                direction: 'asc'
            }
        }

        for (let column of this.gridCfg.columns) {
            if (column.field === field) {
                column.sortDirection = this.sort[f].direction;
            }
            else {
                column.sortDirection = '';
            }
        }

        Object.keys(this.sort).forEach(field => {
            this.gridCfg.api.data.direction = this.sort[field].direction;
            this.gridCfg.api.data.sortBy = field;
        });

        if (this.gridCfg.infiniteScroll) {
            this.gridCfg.api.data.pageNumber = 1;
            this.page = 1;
        }

        if (this.gridCfg.onSortChanged) {
            this.gridCfg.onSortChanged({ direction: this.gridCfg.api.data.direction, field: this.gridCfg.api.data.sortBy })
        }

        this.getData();
    }

    loadData(): void {
        if (!this.lastPage) {
            this.page++;
            this.gridCfg.api.data.pageNumber = this.page;
            this.disableInfiniteScroll = true;
            this.getData(false, true);
        }
    }

    private initGrid(): void {
        this.page = 1;
        this.data = [];
        this.lastPage = false;
        this.getData(true, this.gridCfg.api.disableBI);

        for (let col of this.gridCfg.columns) {
            if (col.sortable && col.sortDirection) {
                this.sort[col.field] = {
                    direction: col.sortDirection
                };
                return;
            }
        }

        if (this.gridCfg.api.data.sortBy && this.gridCfg.api.data.direction) {
            this.sort[this.gridCfg.api.data.sortBy] = {
                direction: this.gridCfg.api.data.direction
            };
            let sortedCol = this.gridCfg.columns.find(c => c.field === this.gridCfg.api.data.sortBy);
            sortedCol && (sortedCol.sortDirection = this.gridCfg.api.data.direction);
            return;
        }
    }

    private getData(firstInit = false, disableBI = false): void {
        this.dataService.api({
            type: this.gridCfg.api.type,
            urlParams: this.gridCfg.api.urlParams,
            data: this.gridCfg.api.data,
            disableBI: disableBI
        }).subscribe(res => {
            if (res.hasNextPage) {
                this.lastPage = false;
                this.disableInfiniteScroll = false;
            } else {
                this.lastPage = true;
            }

            if (this.gridCfg.wrapResultArray) {
                this.data = res;
            } else {
                let loadedData = this.gridCfg.gridDataKey ? res[this.gridCfg.gridDataKey] : res;
                this.data = this.data.concat(loadedData);
            }
            if (this.gridCfg.onDataFetch)
                this.gridCfg.onDataFetch(this.data);
            this.showNoDataMsg = (this.gridCfg.noDataMsg && (this.data.length === 0));
            firstInit && this.calcGridColWidth();
        });
    }

    private calcGridColWidth(): void {
        let width = 100;
        let divider = this.gridCfg.columns.length;

        this.gridCfg.columns.forEach(col => {
            if (col.width) {
                width -= col.width;
                divider--;
            }
            if (col.offset) {
                width -= col.offset;
            }
        });

        this.gridColWidth = (width / divider);
    }
}
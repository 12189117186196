import { Injectable } from '@angular/core';
import { AuthService } from 'app/services/auth.service';

class PermissionsItem {
    showUsersPage: boolean;
}

interface PermissionsCfg {
    [role: string]: PermissionsItem
}

@Injectable()
export class PermissionsService extends PermissionsItem {

    constructor(private authService: AuthService) {
        super();
        // let user = this.authService.user;
        // let premsObj = user && this.permMap[user.roleId];

        // for (let key in premsObj) {
        //     this[key] = premsObj[key];
        // }
    }

    private permMap: PermissionsCfg = {
        Admin: {
            showUsersPage: true
        }
    }
}

import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { GridCfg } from 'app/components/grid/grid.component';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PanZoomModel } from 'ng2-panzoom';

@Component({
  selector: 'orev-seen-near',
  templateUrl: './seen-near.html',
  styleUrls: ['./seen-near.scss']
})
export class SeenNearDialog {

  gridCfg: GridCfg;
  ITEMS_TO_DISPLAY = 40;
  listData;
  currentSeenNear;
  rows = [];
  zoomLevel;
  keyName = 'id';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.setGridCfg();
  }

  private setGridCfg() {
    this.gridCfg = {
      infiniteScroll: true,
      gridDataKey: 'items',
      keyName: 'id',
      noDataMsg: 'seenNear.noData',
      api: {
        type: 'getSeenNearOccurences',
        data: {
          itemsInPage: null,
          pageNumber: 1,
          sortBy: null,
          direction: 'desc',
          civilIdOrigin: this.data.civilIdOrigin,
          civilIdMatched: this.data.civilIdMatched,
        }
      },
      columns: [
        {
          title: 'seenNearDialog.mediaType',
          field: 'mediaType',
          width: 10
        }, {
          title: 'seenNearDialog.mediaName',
          field: 'mediaName',
          width: 20
        },
        {
          title: 'seenNearDialog.time',
          field: 'time',
          width: 25
        },
        {
          title: 'seenNearDialog.originalSimilarity',
          field: 'originalSimilarity',
          width: 18
        },
        {
          title: 'seenNearDialog.matchedSimilarity',
          field: 'matchedSimilarity',
          width: 20
        }
      ],
      iconType: 'more_horiz',
      onIconClick: this.handleIconClick,
      onRowClick: this.handleRowClick,
      onDataFetch: this.handleDataFetched,
    };
  }
  handleIconClick = (rows) => {
    alert();
  }
  handleRowClick = (rows) => {
    this.currentSeenNear = { ...rows };
    if (this.currentSeenNear.mediaType == 'Image') {
      this.rows = [this.currentSeenNear.matchedBoundingBox, this.currentSeenNear.originalBoundingBox];
      this.getImage(this.rows);
    }
  }

  selectImage = (item) => {
    this.getImage(this.rows);
  }

  handleDataFetched = (rows) => {
    this.listData = rows;
  }

  getImage = (rows) => {
    this.rows = rows.map((row, i) => {
      // The small frames of faces
      row.style = {
        position: 'absolute',
        'top.%': row.Top * 100,
        'left.%': row.Left * 100,
        'width.%': row.Width * 100,
        'height.%': row.Height * 100,
        'border-style': 'solid',
        'border-color': i === 0 ? 'green' : 'red',
        'border-width': this.zoomLevel > 2 ? '1px' : '2px',
        'opacity': this.zoomLevel > 2 ? 0.5 : 1,
        'cursor': 'pointer',
      }
      return row;
    });

    // [hack] - Angular doesn't recognize the rows obj change even with cloneDeep 
    this.cd.detectChanges();
  }

  handleZoomed = (model: PanZoomModel) => {
    if (!Number.isInteger(model.zoomLevel)) return;
    this.zoomLevel = model.zoomLevel;
    this.getImage(this.rows)
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


import { FormService } from 'app/services/form.service';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { ModalService } from 'app/services/modal.service';
import { BusyIndicatorService } from 'app/services/busy-indicator.service';
import { TokensService } from 'app/services/tokens.service';

@Component({
    selector: 'mfa',
    templateUrl: './mfa.modal.html',
    styleUrls: ['./mfa.modal.scss']
})

export class MFADialog {
    code;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<MFADialog>) { }

    handleInputChange(e) {
        this.code = e.target.value;
    }

    submit() {
        if (this.code) {
            this.dialogRef.close({verificationCode: this.code});
        }
    }
}
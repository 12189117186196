import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Inject } from '@angular/core';

import { FormService } from 'app/services/form.service';
import { DataService } from 'app/services/data.service';
import { I18nPipe } from 'app/pipes/i18n.pipe';

@Component({
    selector: 'delete-ids',
    templateUrl: './delete-ids.modal.html',
    styleUrls: ['./delete-ids.modal.scss']
})

export class DeleteIdsDialog {

    error: string;
    deleteIdsForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DeleteIdsDialog>,
        private dataService: DataService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private i18n: I18nPipe) { }

    ngOnInit() {
        this.setForm();
    }

    deleteIds() {
        if (this.deleteIdsForm.invalid) return;

        this.dataService.api({
            type: 'deleteId',
            data: {
                collectionKey: this.data.collection.collectionKey,
                civilIds: this.deleteIdsForm.get('civilIds').value.split('\n')
            }
        }).subscribe(() => this.dialogRef.close());
    }

    private setForm() {
        this.deleteIdsForm = this.formBuilder.group({
            civilIds: [null, {
                validators: [
                    this.formService.validators.requiredValue
                ]
            }]
        });
    }
}
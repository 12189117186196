import { Injectable } from "@angular/core";

/**
 * Created this srvc to avoid circular dependency... 
 */
@Injectable()
export class TimingEventsService {
    timeouts = [];
    intervals = [];

    getTimeout(id) {
        return this.timeouts.find(savedId => savedId === id);
    }

    getInterval(id) {
        return this.intervals.find(savedId => savedId === id);
    }

    pushTimeout(id) {
        this.timeouts.push(id);
    }

    pushInterval(id) {
        this.intervals.push(id);
    }

    clearTimeout(id) {
        this.timeouts = this.timeouts.filter(savedId => savedId !== id);
        clearTimeout(id);
    }

    clearInterval(id) {
        this.intervals = this.intervals.filter(savedId => savedId !== id);
        clearInterval(id);
    }

    clearAll() {
        this.timeouts.forEach(id => {
            clearTimeout(id);
        });
        this.intervals.forEach(id => {
            clearInterval(id);
        });

        console.log('[TimingEvents] - cleared all evts', new Date());
    }
}
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'app/services/data.service';

@Component({
  selector: 'orev-edit-civil-id-modal',
  templateUrl: './edit-civil-id-modal.html',
  styleUrls: ['./edit-civil-id-modal.scss']
})
export class EditCivilIdDialog {
  civilId;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditCivilIdDialog>,
    private dataService: DataService) { }
  ngOnInit() {
    this.civilId = this.data.civilId;
  }
  submit() {
    this.dataService.api({
      type: 'changeId',
      data: {
        collectionKey: this.data.collectionKey,
        id: this.data.civilId,
        newId: this.civilId,
      }
    }).subscribe(() => this.dialogRef.close('success'));
  }
}

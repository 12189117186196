export const errTypes = [
    'requiredValue',
    'requiredPassword',
    'requiredIdNumber',
    'numberPattern',
    'idNumberPattern',
    'passwordPattern',
    'emailPattern',
    'phonePattern',
    'passwordsMismatch',
    'rangeValidation',
    'rangeValidationMin',
    'rangeValidationMax',
    'invalidId'
];

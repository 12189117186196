import { NgModule, Optional, SkipSelf } from '@angular/core';

// services
import { BusyIndicatorService } from 'app/services/busy-indicator.service';
import { LocalStorageService } from 'app/services/local-storage.service';
import { UploadFilesService } from 'app/services/upload-files.service';
import { PermissionsService } from 'app/services/permissions.service';
import { AwsAuthService } from 'app/services/aws-auth.service';
import { EventService } from 'app/services/event.service';
import { ModalService } from 'app/services/modal.service';
import { FormService } from 'app/services/form.service';
import { DataService } from 'app/services/data.service';
import { AuthService } from 'app/services/auth.service';

// pipes
import { I18nPipe } from 'app/pipes/i18n.pipe';
import { TokensService } from 'app/services/tokens.service';
import { TimerService } from 'app/services/timer.service';
import { DataEventService } from 'app/services/data-event.service';
import { TimingEventsService } from 'app/services/timingEvents.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        BusyIndicatorService,
        LocalStorageService,
        UploadFilesService,
        PermissionsService,
        AwsAuthService,
        FormService,
        ModalService,
        EventService,
        DataEventService,
        DataService,
        AuthService,
        TokensService,
        TimerService,
        TimingEventsService,
        I18nPipe
    ]
})

export class CoreModule {    
    // This guards against the CoreModule from being imported into any
    // module except the root-module (AppModule).
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from 'app/services/data.service';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'tags-filter',
    templateUrl: './tags-filter.component.html',
    styleUrls: ['./tags-filter.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TagsFilterComponent,
        multi: true
    }]
})
export class TagsFilterComponent implements ControlValueAccessor {
    // TODO - in the future maybe add entityType 
    onChange: Function; // ControlValueAccessor changeFunc
    filteredKeys: any[];
    filteredValues: any[];
    tags: any[];
    form: FormGroup = this.formBuilder.group({
        keyName: null,
        valueName: null,
    });
    ngDestroy$: Subject<boolean> = new Subject<boolean>();
    DEBOUNCE_TIME = 350;

    get disableAddTagBtn() {
        const { keyName, valueName } = this.form.value;
        return !(keyName && valueName);
    }

    constructor(private dataService: DataService,
        private formBuilder: FormBuilder) { }

    ngOnInit() {
        // Call key api when user first keydown + debounce 
        this.getTagKeys();
        this.getTagValues()
        this.setFormEvents();
    }

    ngOnDestroy() {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
    }

    clear() {
        this.form.reset();
    }

    setFormEvents() {
        this.form.get('keyName').valueChanges.pipe(
            takeUntil(this.ngDestroy$),
            debounceTime(this.DEBOUNCE_TIME)).subscribe(val => {
                this.getTagKeys();
            });
        this.form.get('valueName').valueChanges.pipe(
            takeUntil(this.ngDestroy$),
            debounceTime(this.DEBOUNCE_TIME)).subscribe(val => {
                this.getTagValues();
            });
    }

    getTagKeys() {
        this.dataService.api({
            type: 'getTagKeys',
            disableBI: true,
            data: {
                // form.get to get the updated ver inside valuechanges subscription
                keyName: this.form.get('keyName').value
            }
        }).subscribe(res => {
            this.filteredKeys = res.keyNames;
        });
    }

    getTagValues() {
        if (!this.form.get('keyName').value) return;
        this.dataService.api({
            type: 'getTagValues',
            disableBI: true,
            data: {
                keyName: this.form.get('keyName').value,
                valueName: this.form.get('valueName').value,
            }
        }).subscribe(res => {
            this.filteredValues = res.valueNames;
        });
    }

    addTag() {
        const { keyName, valueName } = this.form.value;
        this.tags.push({ key: keyName, value: valueName })
        this.onChange(this.tags);
        this.form.reset();
    }

    handleTagRemoved() {
        this.onChange(this.tags);
    }

    writeValue(val) {
        this.tags = val || [];
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched() { }
}
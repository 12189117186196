import { MAT_DIALOG_DATA } from '@angular/material';
import { PanZoomModel } from 'ng2-panzoom';
import { DataService } from 'app/services/data.service';
import { Component, Inject, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'single-object',
    templateUrl: './single-object.html',
    styleUrls: ['./single-object.scss']
})
export class SingleObjectDialog {
    rows = [];
    zoomLevel;
    selectedItem;
    labelInstances = [];
    displayedColumns = [
        'occuredAt',
        'confidence'
    ];
    selectedRowId;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private cd: ChangeDetectorRef, private dataService: DataService) { }
    ngOnInit() {
        this.dataService.api({
            type: 'getLabelInstances',
            data: this.data.type == 'batch' ? { batchId: this.data.id, labelName: this.data.labelName } : { jobId: this.data.id, labelName: this.data.labelName }
        }).subscribe(res => {
            this.labelInstances = res.items.instances;
        })
    }

    handleRowClick = (rows) => {
        this.selectedRowId = this.labelInstances.indexOf(rows);
        this.selectedItem = { ...rows };
        this.rows = [this.selectedItem.boundingBox]
        this.getImage(this.rows);
    }

    selectImage = (item) => {
        this.getImage(this.rows);
    }

    getImage = (rows) => {
        this.rows = rows.map(row => {
            row.style = {
                position: 'absolute',
                'top.%': row.Top * 100,
                'left.%': row.Left * 100,
                'width.%': row.Width * 100,
                'height.%': row.Height * 100,
                'border-style': 'solid',
                'border-color': 'red',
                'border-width': this.zoomLevel > 2 ? '1px' : '2px',
                'opacity': this.zoomLevel > 2 ? 0.5 : 1,
                'cursor': 'pointer',
            }
            return row;
        });

        // [hack] - Angular doesn't recognize the rows obj change even with cloneDeep 
        this.cd.detectChanges();
    }

    handleZoomed = (model: PanZoomModel) => {
        if (!Number.isInteger(model.zoomLevel)) return;
        this.zoomLevel = model.zoomLevel;
        this.getImage(this.rows)
    }
}
/**
 * This is the global busy-indicator (BI). It observes the process-counter from BusyIndicatorService.
 * When the counter is positive, it shows itself. Otherwise, it is hidden.
 */
import { FormControl, FormGroupDirective, NgForm, FormGroup, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Component, Input } from '@angular/core';
import { startWith, takeUntil } from 'rxjs/operators';

import { errTypes } from 'app/maps/error-types';
import { I18nPipe } from 'app/pipes/i18n.pipe';
import { Subject } from 'rxjs';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'input-with-error',
    templateUrl: './input-with-error.component.html',
    styles: [
        `mat-form-field {
            width: 100%;
            margin-bottom: 15px;
        }`
    ]
})
export class InputWithErrorComponent {

    @Input() type = 'text';
    @Input() form: FormGroup;
    @Input() formCtrlName: string;
    @Input() placeholder: string;
    @Input() errorValidationData: any;
    @Input() dir: string;
    errDisplay: string;
    formCtrl: AbstractControl;
    matcher = new MyErrorStateMatcher();
    ngDestroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private i18n: I18nPipe) { }

    ngOnInit() {
        this.formCtrl = this.form.get(this.formCtrlName);
        this.formCtrl.valueChanges.pipe(takeUntil(this.ngDestroy$))
            .pipe(startWith(this.formCtrl.value))
            .subscribe(() => this.renderErrors());
    }

    ngOnDestroy() {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
    }

    private renderErrors() {
        this.errDisplay = '';
        if (!this.formCtrl.errors) return;
        
        errTypes.forEach(type => {
            if (this.formCtrl.hasError(type)) {
                this.errDisplay += this.i18n.transform('validation.' + type, this.errorValidationData);
            }
        });
    }
}

const i18nMap = {
	G: {
		add: 'הוסף',
		all: 'הכל',
		areYouSure: 'האם הינך בטוח?',
		back: 'חזור',
		cancel: 'ביטול',
		clear: 'נקה',
		close: 'סגור',
		copy: 'העתק',
		delete: 'מחק',
		edit: 'ערוך',
		email: 'אימייל',
		filter: 'סנן',
		firstname: 'שם פרטי',
		id: 'תעודת זהות',
		userName: 'שם משתמש',
		civilId: 'מספר תעודת זהות',
		lastname: 'שם משפחה',
		no: 'לא',
		ok: 'אישור',
		save: 'שמור',
		search: 'חפש',
		submit: 'שלח',
		yes: 'כן',
		imgAlt: 'תמונה',
		loading: 'טוען...',
		seenNear: 'ראיתי ליד',
		backCollection: 'בחזרה למאגר',
		editCivilId: 'ערוך ת.ז.',
		deleteIds: 'מחק ת"זים',

	},
	login: {
		title: 'כניסה למערכת',
		login: 'כניסה',
		password: 'סיסמא',
		inputOTP: 'נא הזן סיסמא שנשלחה לטלפון הנייד',
		forgotPassword: 'שכחתי סיסמה',
	},
	forgotPassword: {
		title: 'שכחתי סיסמה',
	},
	serverErrors: {
		default0: 'קיימת שגיאה פנימית בגישה למשאב המבוקש',
		default400: 'שגיאת הזדהות או מבנה הבקשה למשאב המבוקש אינו תקין',
		default401: 'אינך מורשה לגשת למשאב המבוקש',
		default403: 'אינך מורשה לגשת למשאב המבוקש',
		default404: 'המשאב המבוקש אינו קיים',
		default405: 'המשאב המבוקש אינו קיים',
		default500: 'קיימת שגיאה פנימית בגישה למשאב המבוקש',
		default503: 'המערכת אינה זמינה כעת, אנא נסה שוב מאוחר יותר או צור קשר עם מנהל מערכת',
		default504: 'המערכת אינה זמינה כעת, אנא נסה שוב מאוחר יותר או צור קשר עם מנהל מערכת',
		NotValidPermission: 'אין לך הרשאה מתאימה לביצוע פעולה זו',
		UserNotFoundException: 'שם משתמש או סיסמא אינם נכונים',
		NotAuthorizedException: 'שם משתמש או סיסמא אינם נכונים',
		InvalidParameterException: 'שם משתמש או סיסמא אינם נכונים',
		NetworkError: 'שגיאת רשת',
		UnknownError: 'שגיאה לא ידועה',
		CodeMismatchException: 'קוד הזדהות לא נכון',
	},
	appNav: {
		rUsureSignout: 'האם אתה בטוח שברצונך להתנתק מהמערכת?',
		sidemenu: {
			collections: 'מאגרים',
			identification: 'תהליכים',
			videosProcessResults: 'תוצאות תהליכי וידאו',
			imagesProcessResults: 'תוצאות הוספת תמונות',
			imagesRkProcessResults: 'תוצאות זיהוי תמונות',
			files: 'סייר קבצים',
			signout: 'התנתק',
			users: 'ניהול משתמשים',
			seenNear: 'ראיתי ליד',
			collection: 'צפיה במאגר',
			person: 'צפיה באדם'
		}
	},
	roles: {
		Admin: 'מנהל מערכת',
	},
	validation: {
		// max: 'יש להזין ערך עד <%= requiredMax %>',
		// min: 'יש להזין ערך החל מ-<%= requiredMin %>',
		// minlength: 'יש להזין לכל הפחות <%= requiredLength %> תווים',
		// maxlength: 'יש להזין עד <%= requiredLength %> תווים',
		// validEmailMsg: 'נא הזן כתובת אימייל חוקית',
		// validIdNumMsg: 'מספר תעודת הזהות שהוקש אינו תקין',
		// numericPatternMsg: 'נא הזן ספרות בלבד',
		// uniqueIdNumMsg: 'מספר זהות קיים במערכת',
		// required: 'שדה חובה',
		// pattern: 'תבנית נדרשת: <%= requiredPattern %>',
		// validNameMsg: 'נא הזן אותיות בלבד',
		// tooFarEndDate: 'לא ניתן להגדיר משתמש פעיל זמנית ליותר מ-7 ימים',
		// tooCloseEndDate: 'לא ניתן להגדיר משתמש פעיל זמנית לזמן בעבר',
		requiredValue: 'שדה חובה',
		requiredIdNumber: 'נא להזין מספר זהות',
		requiredPassword: 'נא להזין סיסמא',
		numberPattern: 'נא להזין ספרות בלבד',
		idNumberPattern: 'נא להזין מספר ת"ז בעל 9 ספרות',
		passwordPattern: 'סיסמה חייבת להכיל לפחות 8 תווים שיכללו לפחות מספר אחד, תו מיוחד אחד, אות קטנה אחת ואות גדולה אחת',
		phonePattern: 'על הטלפון להכיל קידומת בינלאומית ללא מקווים, לדוגמה פלוס ואחריו 01234567890',
		emailPattern: 'על כתובת המייל להיות בפורמט הבא example@example.com',
		passwordsMismatch: 'הסיסמא אינה תואמת לאישור הסיסמא',
		rangeValidation: 'נא בחרי סכום בין <%= max %> - <%= min %>',
		rangeValidationMin: 'נא בחר סכום הגבוה מ <%= min %>',
		rangeValidationMax: 'נא בחר סכום הנמוך מ <%= max %>',
		invalidId: 'מספר ת"ז שהוזן לא תקין'
	},
	collections: {
		deleteCivilId: 'מחק ת"ז ממאגר',
		searchCivilId: 'חפש ת"ז',
		failedIndexImages: 'תמונות שלא הצליחו לעבור אינדוקס',
		uploadVideo: 'העלה וידאו לזיהוי',
		uploadYoutubeVideo: 'העלאת וידאו מ-youtube',
		rUsureDeleteCollection: 'האם אתה בטוח שברצונך למחוק את מאגר <br><strong><%= collectionName %></strong>?',
		deleteCollection: 'מחק מאגר',
		collection: 'מאגר',
		tag: 'תווית',
		tags: 'תוויות',
		createCollection: 'צור מאגר',
		collectionName: 'שם מאגר',
		collectionDescription: 'תיאור',
		faceCount: 'כמות',
		actions: 'פעולות',
		editTags: 'ערוך תוויות',
		watchCollection: 'צפה במאגר',
		selecAll:'בחר הכל',
		clearAll:'נקה סימון'
	},
	collection: {
		people: 'אנשים',
		faces: 'פרצופים',
		createdBy: 'נוצר על ידי',
		createDate: 'נוצר בתאריך',
		changeName: 'שנה שם',
		delete: 'מחק',
		imageResult: 'תוצאות תמונות',
		videoResult: 'תוצאות וידאו',
		anonCollection: 'מאגר אלמוני',
		results: 'תוצאות',
		placeholder: 'ת.ז',
		civilId: 'ת.ז',
		count: 'מספר הופעות',
		tags: 'תוויות',
		parentCollection: 'עבור למאגר אב',
		anonymousCollection: 'עבור לפלוני אלמוני'

	},
	tags: {
		key: 'מפתח',
		value: 'ערך',
		copyMessage: '<%=value %> הועתק בהצלחה',
	},
	editTags: {
		title: 'ערוך תוויות',
	},
	tagsFilter: {
		add: 'הוסף תווית לפילטור',
	},
	jobResults: {
		noFilter: 'ללא פילטר',
		displayOnly: 'הצג רק',
		notDisplay: 'אל תציג',
		object: 'אובייקט',
		count: 'כמות'
	},
	singleObject: {
		confidence: 'אחוז',
		occuredAt: 'מקור/זמן'
	},
	identification: {
		all: 'הכל',
		collectionKey: 'מאגר',
		type: 'סוג',
		batchName: 'מקבץ',
		jobId: 'מזהה תהליך',
		jobName: 'מקור',
		sourceName: 'מקור',
		jobStartTime: 'תחילת תהליך',
		jobEndTime: 'סיום תהליך',
		jobStatus: 'סטטוס',
		jobLog: 'סיבת כישלון',
		chooseMsg: 'לא נבחר מאגר',
		noData: 'לא נמצאו תהליכים למאגר המבוקש',
		timeDelta: 'משך תהליך',
		videoStartTime: 'זמן התחלה בוידאו',
		videoStopTime: 'זמן סיום בוידאו',
		videosIdentificationProcs: 'תהליכי זיהוי וידאו',
		imagesIdentificationProcs: 'תהליכי זיהוי תמונות',
		TranscriptionProcs: 'תהליכי תמלול',
		imagesIndexProcs: 'תהליכי הוספת תמונות',
		progress: {
			succeed: 'הצלחה',
			failed: 'כישלון',
			total: 'סה"כ',
		}
	},
	processResults: { // Images and videos combined
		allSrcDesc: 'כל המקורות',
		srcDesc: 'מקור',
		collectionKey: 'מאגר',
		confidence: 'רמת וודאות',
		videoTime: 'זמן וידאו',
		realTime: 'זמן אמיתי',
		chooseMsg: 'לא נבחר מאגר',
		noData: 'לא נמצאו תוצאות לתנאי המבוקש',
		startDatetime: 'זמן התחלה',
		endDatetime: 'זמן סיום',
		startTimeErr: 'יש להזין זמן התחלה',
		endTimeErr: 'יש להזין זמן סיום',
		csv: 'CSV',
		imageName: 'שם תמונה',
		batchName: 'מקבץ',
		jobName: 'מקור',
		allBatches: 'כל המקבצים',
		withImagesUrls: 'עם תמונות',
		distinct: 'מופע בודד',
		onlyRecognized: 'זיהויים בלבד',
	},
	uploadVideoDialog: {
		title: 'העלאת וידאו לזיהוי למאגר <br><strong><%= collectionName %></strong>',
		choose: 'בחר קובץ',
		upload: 'העלה',
		startDatetime: 'זמן התחלה',
		endDatetime: 'זמן סיום',
		jobName: 'תיאור',
		transcription: 'שלח גם לתהליך תמלול',
		anonymousIden: 'שלח גם לזהוי אלמונים'
	},
	uploadYoutubeVideoDialog: {
		title: 'העלאת וידאו מ-youtube לזיהוי למאגר <br><strong><%= collectionName %></strong>',
		upload: 'העלה',
		jobName: 'תיאור',
		youtubeUrl: 'כתובת',
		useYoutubeDesc: 'השתמש בכותרת מיוטיוב',
	},
	createCollectionDialog: {
		title: 'צור מאגר',
		collectionName: 'שם מאגר',
		description: 'תיאור',
	},
	deleteIdsDialog: {
		title: 'מחק ת"ז ממאגר <br><strong><%= collectionName %></strong>',
		idNumbers: 'מספרי ת"ז',
		error: 'מספרי תעודות הזהות:<br><%= invalidValues %><br>אינם תקינים'
	},
	searchIdDialog: {
		title: 'חפש ת"ז במאגר <br><strong><%= collectionName %></strong>',
		sumFaces: 'מספר תמונות'
	},
	displayFailedsDialog: {
		title: 'תמונות שנכשלו באינדוקס במאגר <br><strong><%= collectionName %></strong>',
		time: 'זמן',
		imageName: 'שם תמונה',
		reason: 'סיבה'
	},
	itemProcessResultDialog: {
		title: 'תוצאות תהליכים <br><strong><%= civilId %> - <%= jobName %></strong>',
		detected: 'זיהוי',
		fullframe: 'תמונה מלאה',
		origin: 'מקור',
		sourceMedia: 'מקור הוידאו:'
	},
	imagesItemProcessResultDialog: {
		title: 'תוצאות תהליכים <br><strong><%= civilId %> <br> <%= batchName %></strong>',
		confidence: 'רמת וודאות - <%=confidence %>',
	},
	users: {
		deleteUserBool: 'האם אתה בטוח שברצונך למחוק את המשתמש?',
		username: 'שם משתמש',
		phoneNumber: 'מספר טלפון',
		phoneNumberVerified: 'מספר מאומת',
		userCreateDate: 'תאריך יצירה',
		userLastModifiedDate: 'תאריך שינוי',
		enabled: 'פעיל',
		userStatus: 'סטטוס',
		editUser: 'עריכה',
		deleteUser: 'מחיקה',
		userDeleteSuccess: 'המשתמש נמחק בהצלחה',
		userDeleteError: 'חלה שגיאה במחיקת המשתמש',
		userCreateSuccess: 'המשתמש נוצר בהצלחה',
		userEditSuccess: 'המשתמש נערך בהצלחה',
	},
	editUserDialog: {
		title: 'עריכת משתמש',
		isAdmin: 'אדמין',

	},
	createUserDialog: {
		title: 'יצירת משתמש',
		username: 'שם משתמש',
		temporaryPassword: 'סיסמה זמנית',
		email: 'אימייל',
		phoneNumber: 'מספר טלפון',
		userCreateError: 'חלה שגיאה ביצירת המשתמש',
	},
	inactivityDialog: {
		noActivity: 'לחץ על הכפתור כדי לחדש פעילות',
		refreshActivity: 'חידוש פעילות',
	},
	setPassword: {
		title: 'שינוי סיסמה',
		password: 'סיסמה',
		confirmPassword: 'סיסמה בשנית',
		passwordNotIdentical: 'הסיסמאות לא זהות',
	},
	mfa: {
		title: 'הזן את הקוד שקיבלת בSMS',
		placeholder: 'קוד',
	},
	files: {
		sendVideoBatch: 'שלח תיקיית הקלטות וידאו לזיהוי',
		sendImageBatch: 'שלח תיקיית תמונות לזיהוי',
		addImageBatch: 'שלח תיקיית תמונות להוספה למאגר',
		goBack: 'Back',
		goRoot: 'Root',
		emptyFolder: 'אין תיקיות',
		amazon: 'Amazon S3',
		google: 'Google Drive'
	},
	videoBatchDialog: {
		title: 'שליחת תיקיית <%= fileKey %> לזיהוי',
		send: 'שלח',
		transcription: 'שלח גם לתהליך תמלול',
		anonymousIden: 'שלח גם לזהוי אלמונים'
	},
	imageBatchDialog: {
		title: {
			add: 'הוספת תיקיית <%= fileKey %> למאגר',
			send: 'שליחת תיקיית <%= fileKey %> לזיהוי',
		},
		send: 'שלח',
	},
	seenNear: {
		civilId: 'ת.ז',
		count: 'מספר הופעות'
	},
	seenNearDialog: {
		mediaType: 'סוג ',
		mediaName: 'שם מקור',
		time: 'זמן',
		matchedSimilarity: ' ודאות הנמצא ליד',
		originalSimilarity: ' ודאות האדם',
		noData: 'לא נמצאו תוצאות',
		originalProfileUrl: 'מקור אדם',
		originalFoundImageUrl: 'זיהוי אדם',
		matchedProfileUrl: 'מקור הנמצא ליד',
		matchedFoundImageUrl: 'זיהוי הנמצא ליד',
	},
	person: {
		indexedBy: 'נוסף על ידי ',
		indexedAt: 'נוסף בתאריך',
		collectionName: 'מתוך מאגר',
		collection: 'מאגר',
		confidence: 'וודאות',
		description: 'שם/תאור',
		occurencesCount: 'תוצאות'
	},
	editCivilId: {
		title: 'ערוך ת.ז',
	},
	deleteCivisIds: {
		title: 'נבחרו הת"זים הבאים למחיקה',
		OK: 'אישור'
	},
};

export { i18nMap };